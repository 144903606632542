import { Model } from "@src/libs/model/model";


export class AppVersionSingle extends Model {
    id: number = 0;
    app_name?: string = "";
    version_name: string = "";
    mandatory: string = "N";
    created_on?: string = "";
    note?: string = "";
    version_code: number = 0;
  }
  