import CheckBox from "@src/libs/ui/CheckBox";
import Modal from "@src/libs/ui/Modal";
import TextAreaInput from "@src/libs/ui/TextAreaInput";
import TextCurrencyInput from "@src/libs/ui/TextCurrencyInput";
import { moneyFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import PaymentMethodStore from "@src/model/paymentmethod-repo";
import PromoStore from "@src/model/promo-repo";
import { PromoSingle } from "@src/model/promo-single";
import { Check } from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { ClipLoader } from "react-spinners";

const ModalSubmitNew: React.FC = observer(() => {
  const currentOrder = OrderStore.updateOrder;
  if (!currentOrder) return null;

  const meta = useLocalObservable(() => ({
    focusedChange: false,
    focusedDiscount: false,
    focusedDiscountAmt: false,
  }));

  const submit = async () => {
    OrderStore.update(true);
  };

   const dismiss = () => {
    currentOrder.promo = new PromoSingle()
    currentOrder.isDiscount=false
    currentOrder.discount="0"
    currentOrder.amount_discount="0"

    runInAction(() => (OrderStore.openPaymentSubmit = false));
  };

  const submitAndComplete = async () => {
    OrderStore.updateAndComplete(true);
    OrderStore.filter = "";
    
  };

  window.addEventListener('online', function(e) { 
    PromoStore.reload();
    PaymentMethodStore.reload();
  });

  return (
    <Modal visible={!!OrderStore.openPaymentSubmit||OrderStore.isSaving} onDismiss={dismiss}>
      <div className="bg-white px-4 pt-4 overflow-auto pb-4">
        <div className="sm:flex sm:items-start">
          <div className="text-center sm:text-left flex flex-1 flex-row">
            <div className="flex-grow">
              

            {currentOrder.payment_status === "NOW" && (
                <>
                  <h2 className="text-s font-bold leading-6 text-gray-600 mb-3">
                    Pilih Metode Pembayaran
                  </h2>
                  <div className={"grid-cols-3 grid gap-1"}>
                    {PaymentMethodStore.list.map((item, idx) => {
                      const active = item.name === currentOrder.payment_method;
                      return (
                        <div
                          key={idx}
                          className="mb-2 mr-5 flex flex-col"
                          
                        >
                          <button
                            className={`opacity-75 hover:opacity-100 border-gray-400 border-2 rounded-lg overflow-hidden relative ${
                              !!active && "border-blue-800"
                            }`}
                            style={{
                              borderColor: !!active ? "#024f94ff" : "#cbd5e0"
                            }}
                            onClick={() => {
                              runInAction(
                                () => (currentOrder.payment_method = item.name)
                              );
                            }}
                          >
                            {!!active && (
                              <>
                                <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-300 opacity-25" />
                                {/* <Check
                                  size={35}
                                  className="absolute top-0 right-0 m-1 text-blue-600 bg-white rounded-full p-1 border border-blue-300"
                                /> */}
                              </>
                            )}

                            <div
                              className={`h-20 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                              style={{
                                backgroundImage: `url(${
                                  ConfigStore.server_url +
                                  "/" +
                                  item.img_url.replace(/ /g, "%20")
                                })`,
                              }}
                            />
                          </button>
                          <div className="text-sm text-center">{item.name}</div>
                        </div>
                      );
                    })}
                  </div>

                  <h2 className="text-s font-bold leading-6 text-gray-600 mb-3">
                  Promo Order
                </h2>
                <div className={"grid-cols-3 grid gap-1"}>
                  {PromoStore.list.map((item, idx) => {
                    const active = item.id === currentOrder.promo.id;
                    return (
                      <div
                        key={idx}
                        className="mb-2 mr-5 flex flex-col"
                        
                      >
                        <button
                          className={`opacity-75 hover:opacity-100 border-gray-400 border-2 rounded-lg overflow-hidden relative ${
                            !!active && "border-blue-800"
                          }`}
                          style={{
                            borderColor: !!active ? "#024f94ff" : "#cbd5e0"
                          }}
                          onClick={() => {
                            runInAction(
                              () => {
                                
                                currentOrder.discount="0"
                                currentOrder.amount_discount="0"
                                if(currentOrder.promo.id === item.id){
                                  currentOrder.promo = new PromoSingle()
                                  currentOrder.isDiscount=false
                                  currentOrder.discount="0"
                                  currentOrder.amount_discount="0"
                                  
                                }else{
                                  currentOrder.promo = item
                                  currentOrder.isDiscount=true
                                  currentOrder.setPromo()
                                }
                                
                              }
                            );
                          }}
                        >
                          {!!active && (
                            <>
                              <div className="absolute top-0 left-0 right-0 bottom-0 bg-blue-300 opacity-25" />
                              {/* <Check
                                size={35}
                                className="absolute top-0 right-0 m-1 text-blue-600 bg-white rounded-full p-1 border border-blue-300"
                              /> */}
                            </>
                          )}
                          <div className="text-sm text-center"
                          style={{
                            fontWeight: "bold",
                            color: "#024f94ff",
                            marginTop:6,
                            marginBottom:6,
                          }}
                          >{item.name}</div>

                          
                        </button>
                        
                      </div>
                    );
                  })}
                </div>
                </>
              )}
            </div>

            <div className="flex flex-col border border-gray-300 rounded-md p-3">

              {/* //HERE 
              focusedChange: false,
    focusedDiscount: false,
    focusedDiscountAmt: false,
              */}
              {(ConfigStore.isWeb || (!meta.focusedChange && !meta.focusedDiscount && !meta.focusedDiscountAmt)) && (
                <div className="flex flex-1 flex-col w-64">
                <h2 className="text-xs mb-1 text-gray-700">Keterangan :</h2>
                <TextAreaInput
                  className="mx-0 my-0 px-2 py-1 text-sm border-solid border rounded text-gray-800 border-gray-400 bg-gray-100 "
                  value={currentOrder.remarks}
                  onChange={(e) => {
                    runInAction(() => (currentOrder.remarks = e.target.value));
                  }}
                  rows={4}
                />
              </div>
              )}
              

              <div className="flex-end flex flex-col justify-items-end">
                <h2 className="text-xs mb-1 text-gray-700  md:font-bold">
                  Total Pembayaran :
                </h2>
                <div className="bg-gray-100 rounded border border-gray-400 col-span-6 flex justify-between flex-row flex-1 p-2 my-2">
                  {/* <h1 className="font-bold text-xl mr-2">Total</h1> */}
                  <h1 className="font-bold text-xl text-right w-full">
                    {moneyFormat(currentOrder.total, "Rp. ")}
                  </h1>
                </div>
                {currentOrder.payment_status === "NOW" && (
                <>

                <div>
                  
                <CheckBox 
                  label="Diskon"
                  checked={currentOrder?.isDiscount}
                  disabled={currentOrder.promo.id!==0}
                  onChange={(e) => {
                    runInAction(() => {
                      currentOrder.isDiscount = !currentOrder.isDiscount;

                      if(!currentOrder.isDiscount){
                        currentOrder.discount="0"
                        currentOrder.amount_discount="0"
                      }
                    });
                  }}
                  
                />
                </div>
               
                  {currentOrder.isDiscount && (
                    <table className="mb-3 w-64">
                  <tr>
                    <td className="mr-1">
                      <h2 className="text-xs mb-1 mt-2 text-gray-700 mr-1">Diskon Persen :</h2>
                    </td>
                    <td className="">
                      <h2 className="text-xs mb-1 mt-2 text-gray-700 ml-1">Diskon Harga :</h2>
                    </td>
                  </tr>
                  <tr>
                  <td className="mr-1">
                    <div>
                      <TextCurrencyInput
                          placeholder="Please enter a number"
                          disabled={currentOrder.promo.id!==0}
                          value={parseFloat(currentOrder.discount)>0?currentOrder.discount:""}
                          onValueChange={(value:any, name:any) => {
                            currentOrder.discount = (!!value?.toString()?value.toString():"0")
                            currentOrder.setDiscount()
                          }}
                          type="percent"
                          max={100}
                          style={{
                            width:75,
                            marginRight: "0.25rem"
                          }}  
                          autoFocus={currentOrder.isDiscount}
                          onFocus={(event:any) => {meta.focusedDiscount=true}}
                          onBlur={(event:any) => {meta.focusedDiscount=false}}
                        />
                        </div>
                    </td>
                    <td className="ml-1">
                      <div className="bg-gray-100 rounded ml-1"
                      style={{
                        width:"100%"
                      }}>
                          <TextCurrencyInput
                          placeholder="Please enter a number"
                          disabled={currentOrder.promo.id!==0}
                          value={parseFloat(currentOrder.amount_discount)>0?currentOrder.amount_discount:""}
                          onValueChange={(value:any, name:any) => {
                            currentOrder.amount_discount = (!!value?.toString()?value.toString():"0")
                            currentOrder.setDiscountAmount()
                          }}
                          type="amount"
                          style={{
                            width:75,
                            marginRight: "0.25rem"
                          }}  
                          
                          onFocus={(event:any) => {meta.focusedDiscountAmt=true}}
                          onBlur={(event:any) => {meta.focusedDiscountAmt=false}}
                        />
                        </div>
                    </td>
                  </tr>
                  </table>
                  )}
                  {!ConfigStore.isWeb && (meta.focusedDiscount || meta.focusedDiscountAmt) &&(
                    <div
                      style={{
                        marginBottom:96
                      }}
                      />
                  )}
              
              <div>
                <CheckBox 
                label="Uang Kembalian"
                checked={currentOrder?.isChange}
                onChange={(e) => {
                  runInAction(() => {
                    currentOrder.isChange = !currentOrder.isChange;
                  });
                }}
                style={{
                  flexShrink:1
                }}
                />
                </div>
                {currentOrder.isChange && (
                  <table  className="mb-3 w-64">
                <tr>
                  <td className="mr-1">
                    <h2 className="text-xs mb-1 mt-2 text-gray-700 mr-1">Nominal Uang:</h2>
                  </td>
                  <td className="">
                    <h2 className="text-xs mb-1 mt-2 text-gray-700 ml-1">Uang Kembalian:</h2>
                  </td>
                </tr>
                <tr>
                <td className="mr-1">
                  <div>
                    <TextCurrencyInput
                        placeholder="Please enter a number"
                        value={currentOrder.cash>0?currentOrder.cash:""}
                        onValueChange={(value:any, name:any) => {
                          currentOrder.cash = parseInt(!!value?.toString()?value.toString():"0")
                          currentOrder.setChanges()
                        }}
                        type="amount"
                        style={{
                          width:75,
                          marginRight: "0.25rem"
                        }}  
                        autoFocus={currentOrder?.isChange}
                        
                        onFocus={(event:any) => {meta.focusedChange=true}}
                        onBlur={(event:any) => {meta.focusedChange=false}}
                      />
                      </div>
                  </td>
                  <td className="ml-1">
                    
                        {/* <h1 className="rounded-md shadow-sm  flex-grow-1 text-right w-full"
                        style={{
                          width:75
                        }} >
                          {moneyFormat(currentOrder.change, "Rp. ")}
                        </h1> */}

                        <TextCurrencyInput
                        placeholder="Please enter a number"
                        value={currentOrder.change}
                        disabled={true}
                        onValueChange={(value:any, name:any) => {
                          currentOrder.cash = parseInt(!!value?.toString()?value.toString():"0")
                          currentOrder.setChanges()
                        }}
                        type="amount"
                        style={{
                          width:75,
                          marginRight: "0.25rem"
                        }}  
                        autoFocus={currentOrder?.isChange}
                        
                        onFocus={(event:any) => {meta.focusedChange=true}}
                        onBlur={(event:any) => {meta.focusedChange=false}}
                      />
                      
                  </td>
                </tr>
                </table>
                )}
                </>
                )}
                {!ConfigStore.isWeb && meta.focusedChange &&(
                  <div
                  style={{
                    marginBottom:96
                  }}
                  />
                )
                }
                
                
                {/* <div className="col-span-6 bg-gray-50 py-3 sm:flex sm:flex-row">
                  <span className="flex rounded-md shadow-sm flex-grow-1 sm:flex-col">
                    <h2 className="text-xs mb-1 text-gray-700">Nominal Uang:</h2>
                    <TextCurrencyInput
                      placeholder="Please enter a number"
                      defaultValue={currentOrder.cash}
                      onValueChange={(value, name) => console.log(value, name)}
                    />
                  </span>


                  <span className="flex rounded-md shadow-sm sm:ml-3  flex-grow-1 sm:flex-col">
                    <h2 className="text-xs mb-1 text-gray-700">Uang Kembalian:</h2>
                    <div className="bg-gray-100 rounded border border-gray-400 p-2 my-2">
                      <h1 className="rounded-md shadow-sm  flex-grow-1">
                        {moneyFormat(currentOrder.changes, "Rp. ")}
                      </h1>
                    </div>
                    
                    
                  </span>

                </div> */}

                {/* <div className="col-span-6 bg-gray-50 py-3 sm:flex sm:flex-row">
                  <span className="flex rounded-md shadow-sm flex-grow-1 sm:flex-col">
                    <h2 className="text-xs mb-1 text-gray-700">Nominal Uang:</h2>
                    <TextCurrencyInput
                      placeholder="Please enter a number"
                      defaultValue={currentOrder.cash}
                      onValueChange={(value, name) => console.log(value, name)}
                    />
                  </span>


                  <span className="flex rounded-md shadow-sm sm:ml-3  flex-grow-1 sm:flex-col">
                    <h2 className="text-xs mb-1 text-gray-700">Uang Kembalian:</h2>
                    <div className="bg-gray-100 rounded border border-gray-400 p-2 my-2">
                      <h1 className="rounded-md shadow-sm  flex-grow-1">
                        {moneyFormat(currentOrder.changes, "Rp. ")}
                      </h1>
                    </div>
                    
                    <TextInput
                      className="mx-0 my-0 px-2 py-1 text-sm border-solid border rounded text-gray-800 border-gray-400 bg-gray-100 "
                      value={currentOrder.changes}
                      onChange={(e) => {
                        runInAction(() => (currentOrder.remarks = e.target.value));
                      }}
                    />
                  </span>

                </div> */}

                <div className="col-span-6 bg-gray-50 py-3 sm:flex sm:flex-row-reverse">
                <span className="flex-1 flex-grow rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                      type="button"
                      className="inline-flex  justify-center items-center w-full rounded-md border border-transparent px-6 py-2 border-blue-600 text-xl leading-6 font-medium text-blue-600 shadow-sm hover:bg-blue-200 focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple  flex-1"
                      onClick={submit}
                      disabled={!currentOrder.canSubmit||OrderStore.isSaving}
                    >
                      {OrderStore.isSaving?
                        <ClipLoader color={"#2b6cb0"} loading={true} size={24} />
                      :
                        (currentOrder.payment_status === "NOW"?"Bayar":"Simpan")
                      }
                    </button>
                  </span>
                  <span className="mt-3 flex-1 flex-grow rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center w-full rounded-md border border-gray-300 px-6 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple flex-1"
                      onClick={dismiss}
                      style={{
                        borderWidth: 1,
                        borderColor: "gray",
                      }}
                      disabled={OrderStore.isSaving}
                    >
                      Batal
                    </button>
                  </span>
                </div>
                {ConfigStore.isQueue===false && 
                  <button
                  type="button"
                  className="inline-flex justify-center items-center w-full rounded-md border border-transparent px-6 py-2 bg-blue-600 text-lg leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple flex-3"
                  onClick={submitAndComplete}
                  disabled={(!currentOrder.canComplete)||OrderStore.isSaving}
                  >
                    {OrderStore.isSaving?
                        <ClipLoader color={"#fff"} loading={true} size={24} />
                      :
                        ("Bayar dan Selesai")
                    }
                  </button>
                }
                
              </div>
            </div>
            {/* <div className={"mb-2 flex flex-col"}>
              <h2 className="text-xs mb-1 text-gray-700">Keterangan :</h2>
              <TextAreaInput
                className="mx-0 my-0 px-2 py-1 text-sm border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                value={currentOrder.remarks}
                onChange={(e) => {
                  runInAction(() => (currentOrder.remarks = e.target.value));
                }}
                rows={4}
              />
            </div> */}
            {/* <div className="bg-gray-100 mb-2 p-2 rounded border border-gray-400 col-span-6"> */}
            {/* <div className="flex flex-row justify-between mb-1">
                  <h1 className=" text-sm">Subtotal</h1>
                  <h1 className=" text-sm">
                    ({currentOrder.itemsQty} Items){" "}
                    {moneyFormat(currentOrder.subtotal, "Rp. ")}
                  </h1>
                </div>
                <div className="flex flex-row justify-between mb-1">
                  <h1 className=" text-sm">Tax</h1>
                  <h1 className=" text-sm">
                    ({currentOrder.ppn}%){" "}
                    {moneyFormat(currentOrder.tax_amount, "Rp. ")}
                  </h1>
                </div>
                <div className="flex flex-row justify-between mb-1">
                  <h1 className=" text-sm">Discount</h1>
                  <h1 className=" text-sm">
                    ({currentOrder.discount}%){" "}
                    {moneyFormat(currentOrder.discount_amount, "Rp. ")}
                  </h1>
                </div> */}
            {/* <div className="flex flex-row justify-between">
                  <h1 className="font-bold text-xl">Total</h1>
                  <h1 className="font-bold text-xl">
                    {moneyFormat(currentOrder.total, "Rp. ")}
                  </h1>
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ModalSubmitNew;
