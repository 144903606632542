import { Plugins } from "@capacitor/core";
import { navigate, RouteComponentProps } from "@reach/router";
import kelava from "@src/assets/images/pos-kelava.jpg";
import Page from "@src/libs/ui/Page";
import TextInput from "@src/libs/ui/TextInput";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore from "@src/model/config";
import UILoading from "@src/ui/Loading";
import Printer from "@src/ui/setup/Printer";
import Printers from "@src/ui/setup/Printers";
import TopBar from "@src/ui/setup/TopBar";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { Suspense } from "react";

const { Device } = Plugins;

const PrintSetup: React.FC<RouteComponentProps> = observer((props) => {
  const form = ConfigStore.setup;
  const paths = ["server_url", "app_name", "app_token"];

  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform === "web") {
      navigate("order-list");
    } else {
      ConfigStore.loadSetup();
    }
  }, []);

  return (
    <Suspense fallback={<UILoading />}>
      <TopBar/>
      <Page {...props} className="h-full w-full grid grid-cols-12 bg-gray-100">
        {{
          default: (
            <>
              <div className="col-span-4 flex flex-col p-4 bg-white border-r border-gray-300">
                <div className="font-bold text-3xl">Pengaturan Printer</div>
                <div className="flex flex-1 justify-center">
                  <div
                    className={`h-56 w-56 flex-none overflow-hidden bg-cover bg-center`}
                    style={{ backgroundImage: `url(${kelava})` }}
                  />
                </div>
              </div>
              <Printers purpose='struk' />
              <Printers purpose="label" />
            </>
          ),
        }}
      </Page>
    </Suspense>
  );
});

export default PrintSetup;
