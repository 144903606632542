import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";

const getList = async (limit: string = "") => {
  const res = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListPaymentMethod`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      limit: limit,
    },
  });

  if (Array.isArray(res)) {
    //console.log(JSON.stringify(res));

    return res;
  }
  return [];
};

const PaymentMethodApi = {
  getList,
};

export default PaymentMethodApi;
