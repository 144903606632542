import { RouteComponentProps } from "@reach/router";
import { AppConfig } from "@src/config/app";
import Page from "@src/libs/ui/Page";
import ConfigStore from "@src/model/config";
import UILoading from "@src/ui/Loading";
import PageFlow from "@src/ui/queue/Flow";
import TopBar from "@src/ui/TopBar";
import React, { Suspense, useEffect } from "react";
//import { Browser } from '@capacitor/browser';

const Summary: React.FC<RouteComponentProps> = (props) => {
    // const openCapacitorSite = async () => {
    //     await Browser.open({ url: 'http://capacitorjs.com/' });
    //   };

    useEffect(() => {
      if(!ConfigStore.isWeb){
        ConfigStore.checkConfigStore()

        
      }
    }, []);
    
      //https://dev.kelava.id/sfa/index.php?r=manager/MainDashboard/DashboardOrderOutletDailyDetailU
      //https://dev.kelava.id/sfa/index.php?r=site/webView&t=0129398asdhj&u=manager/MainDashboard/DashboardOrderOutletDailyDetailU

      console.log(AppConfig.server+"index.php?r=site/webView&t="+ConfigStore.user_token+"&u=manager/MainDashboard/DashboardOrderOutletDailyDetailU")
      // alert(AppConfig.server+"index.php?r=site/webView&t="+ConfigStore.user_token+"&u=manager/MainDashboard/DashboardOrderOutletDailyDetailU")
    return (
      <Suspense fallback={<UILoading />}>
        <Page {...props} className="h-full w-screen bg-gray-100">
          {{
            default: (
              <div className="h-full w-full overflow-hidden flex flex-col">
                <TopBar className="flex"  totem={ConfigStore.selectedTotem}/>
                <iframe src={AppConfig.server+"index.php?r=site/webView&t="+ConfigStore.user_token+"&u=manager/MainDashboard/DashboardOrderOutletDailyDetailU"} 
                width="100%" height="100%"
                id="summarywv"
                sandbox='allow-scripts allow-same-origin'
                referrerPolicy="same-origin"
                allow='allow-scripts allow-same-origin'
                
                 ></iframe>
                
              </div>
            ),
          }}
        </Page>
      </Suspense>
    );
  };
  
  export default Summary;
  