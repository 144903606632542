import { moneyFormat } from "@src/libs/utils/stringFormat";
import OrderStore from "@src/model/order-repo";
import ProductStore from "@src/model/product-repo";
import { ProductSingle } from "@src/model/product-single";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";

interface IProductItem {
  index: number;
  currentOrder: any;
}

const ProductList: React.FC<IProductItem> = observer((props) => {
  const { index, currentOrder } = props;
  let item: ProductSingle = new ProductSingle();

  if (currentOrder.productMember.list.length > 0) {
    item = currentOrder.productMember.listProduct[index];
  } else {
    item = ProductStore.listProduct[index];
  }

  if (!item?.id) return null;

  const action = () => {
    runInAction(() => {
      OrderStore.setOrderProduct(item);
    });
  };
  return (
    <button
      className={`ripple button w-full h-full text-left rounded-lg bg-white border-gray-300 overflow-hidden hover:shadow-lg hover:border-blue-400`}
      onClick={action}
    >
      <div
        className={`w-full h-full bg-cover bg-center flex items-end`}
        style={{ backgroundImage: `url("${item.image}")` }}
      >
        {!!item.code && (
          <div className="absolute top-0 right-0 m-1 bg-white bg-opacity-75 px-2 rounded-full text-sm font-bold">
            {item.code}
          </div>
        )}
        <div
          className={`flex flex-1 flex-col`}
          style={{
            backgroundColor: "#ffffffcc",
          }}
        >
          <div className="py-2 px-2">
            <h1 className={"text-md font-bold text-gray-800 max-h-4"}>
              {item.product_name}
            </h1>
            <h4 className="text-gray-800 text-sm leading-3">
              {moneyFormat(item.price, "Rp. ")}
            </h4>
          </div>
        </div>
      </div>
    </button>
  );
});

export default ProductList;
