import { dateFormat, moneyFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import DataIsEmpty from "../DataIsEmpty";
import DataIsLoading from "../DataIsLoading";

export default observer(() => {

  
  const [width, setWidth] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    if(!!ref.current){
      setWidth(ref.current!!.clientWidth)
    }
  })

  if (OrderStore.getList.length === 0) {

    if(OrderStore.loading){
      return (
        <div className="col-span-4 lg:col-span-3 overflow-auto overflow-x-hidden bg-white h-full">
          <DataIsLoading
            description={"Sedang Memuat."}
          />
        </div>
      );
    }else{
      return (
        <div className="col-span-4 lg:col-span-3 overflow-auto overflow-x-hidden bg-white h-full">
          <DataIsEmpty
            description={"Tidak ada data."}
            onRefresh={() => OrderStore.load()}
          />
        </div>
      );
    }
  }
  
  return (  
    <div className="col-span-4 lg:col-span-3 overflow-auto overflow-x-hidden bg-white h-full"
      ref = { ref }
    >
        <ul
        style={{
          paddingBottom:"96px"
        }}>         
        {/* <div className="col-span-4 lg:col-span-3 bg-white h-full"> */}
          <div className="divide-y border-gray-300 overflow-scroll h-full">
          
            {OrderStore.getList.map((item) => {
              return (
                <div
                  key={String(item.id)}
                  className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                    item.id === OrderStore.idDetailOrder ?
                    "bg-blue-100 hover:bg-blue-100 border-l-4 border-blue-700":"bg-white"
                  }`}
                  onClick={() =>
                    runInAction(() => (OrderStore.idDetailOrder = item.id))
                  }
                >

                  <div className="flex flex-row">
                    <div className="text-gray-800 font-bold text-base lg:text-sm flex flex-grow">
                      {item.additional_customer_name}
                    </div>
                    {
                      item.src=="CRP" &&
                      <div className="text-red-600 font-bold text-base lg:text-base">
                        {OrderStore.crpList.find(x => x.id == item.id)?.isRead==false?"●":""}
                        {/* {OrderStore.crpList.find(x => x.id == item.id)?.date+""} */}
                      </div>
                    }
                    
                  </div>
                  <div className="text-gray-800 font-bold text-base lg:text-sm">
                    #{item.sales_order_number} - {item.src}
                  </div>
                  {!!item.phone && item.phone!=="null" && (
                    <div className="text-gray-600 text-base lg:text-sm">
                      Telp. {item.phone}
                    </div>
                  )}
                  <div className="text-gray-600 text-base lg:text-sm overflow-hidden truncate flex-row flex">
                    {dateFormat(item.sales_order_date, "EEE, d MMM - HH:mm")} -{" "}
                    <span className="capitalize flex flex-grow">{item.so_status}</span>

                    {/* disable queue */}
                    {/* {item.queue!==""&&item.queue!=="null" &&(
                      <span style={{
                        justifyContent:'flex-end',
                        fontWeight: 'bold',
                        fontSize:24,
                        color:item.q_status==0?"#ed8936":item.q_status==1?"#339949":"#CCCCCC"//FF5C00
                        }}>{item.queue}</span>
                    )} */}
                    
                  </div>
                  
                  <div className="text-gray-600 text-base lg:text-sm overflow-hidden truncate flex-row flex">
                    {moneyFormat(item.grand_total, "Rp. ")}

                    {!!item.payment_method && (item.payment_method!=="" && item.payment_method!=="null") ?
                      <span className="capitalize flex flex-grow ml-1">{" "}({item.payment_method})</span>
                      :
                      <span className="capitalize flex flex-grow ml-1">{" "}</span>
                    }
                    

                    {/* disable queue */}
                    {item.queue!==""&&item.queue!=="null" &&(
                      <span style={{
                        justifyContent:'flex-end',
                        fontWeight: 'bold',
                        fontSize:24,
                        color:item.q_status==0?"#ed8936":item.q_status==1?"#339949":"#CCCCCC"//FF5C00
                        }}>{item.queue}</span>
                    )}
                    
                  </div>
                </div>
              );
            })}
           
          </div>
        </ul>
        <div 
        className={`flex flex-col py-4 px-5 h-24 absolute bottom-0`}//${ConfigStore.isWeb?"w-1/4":"w-1/3"}
        style={{
          backgroundColor:"#EDF8FF",
          borderTopWidth:3,
          borderColor:"#5A98DB",
          alignItems:"stretch",
          width:width
        }}
        >
          Summary Penjualan: 
          <span style={{
            justifyContent:'flex-end',
            fontWeight: 'bold',
            fontSize:24,
            textAlign:"end"
            }}>
              {moneyFormat(OrderStore.getSummary, "Rp. ")}
           </span>
          
        </div>
  </div>
  );
});

                    

                    {/* <Icon
                      icon={ic_hourglass_full}
                      size={16}
                      style={{
                        paddingRight: 4,
                        backgroundSize: 16,
                        color: "#024f94ff",
                      }}
                    /> */}