import { moneyFormat } from "@src/libs/utils/stringFormat";
import { observer } from "mobx-react";
import React from "react";
import OrderStore from "@src/model/order-repo";
import CurrentOrderItems from "./CurrentOrderItems";

const CurrentOrder: React.FC = observer(() => {
  const currentOrder = OrderStore.currentOrder;

  const saveOrder = () => {
    OrderStore.openPayment = true;
  };

  return (
    <div className="md:col-span-4 xl:col-span-3 flex flex-col border-l border-gray-400 overflow-hidden h-full"
    >
      <CurrentOrderItems currentOrder={currentOrder} />
      <div className="flex flex-col p-2">
        <div className="bg-white rounded border border-gray-300 flex flex-col">
          {currentOrder.items.length > 0 && (
            <>
              {/* <div className="flex flex-row justify-between mb-1">
              <h1 className=" text-sm">Subtotal</h1>
              <h1 className=" text-sm">
                ({currentOrder.itemsQty} Items){" "}
                {moneyFormat(currentOrder.subtotal, "Rp. ")}
              </h1>
            </div>
            <div className="flex flex-row justify-between mb-1">
              <h1 className=" text-sm">Tax</h1>
              <h1 className=" text-sm">
                ({currentOrder.ppn}%){" "}
                {moneyFormat(currentOrder.tax_amount, "Rp. ")}
              </h1>
            </div>
            <div className="flex flex-row justify-between mb-1">
              <h1 className=" text-sm">Discount</h1>
              <h1 className=" text-sm">
                ({currentOrder.discount}%){" "}
                {moneyFormat(currentOrder.discount_amount, "Rp. ")}
              </h1>
            </div> */}
              <div className="flex flex-row justify-between m-2">
                <h1 className="font-bold text-xl">Total</h1>
                <h1 className="font-bold text-xl">
                  {moneyFormat(currentOrder.total, "Rp. ")}
                </h1>
              </div>
            </>
          )}
          <button
            className="bg-blue-500 text-white p-2 font-bold text-lg rounded hover:shadow-lg ripple text-center"
            disabled={currentOrder.items.length < 1}
            onClick={saveOrder}
          >
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
});

export default CurrentOrder;
