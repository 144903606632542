import { Model } from "@src/libs/model/model";
import CustomerApi from "../api/CustomerApi";
import { CustomerSingle } from "./customer-single";

export class CustomerRepository extends Model {
  list: CustomerSingle[] = CustomerSingle.hasMany(this);

  public async reload() {
    await CustomerApi.getList().then((res: any) =>
      this._loadJSON({
        list: res,
      })
    );
  }
}

// this.list.push(
//   new CustomerSingle({
//     id: uuid(),
//     code: "111",
//     name: "Khoirul",
//     phone: "086734673",
//   }),
//   new CustomerSingle({
//     id: uuid(),
//     code: "222",
//     name: "Hadi",
//     phone: "012312312",
//   })
// );

const CustomerStore = CustomerRepository.create({
  localStorage: false,
  storageName: "CustomerRepository",
});
export default CustomerStore;
