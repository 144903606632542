import { Plugins } from "@capacitor/core";
import SessionApi from "@src/api/SessionApi";
import { dateFormat, moneyFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { Session } from "inspector";
import QRCode from "qrcode";

// const imageToBase64 = require("image-to-base64");
const PrintBT = require("thermal-printer-cordova-plugin/www/thermal-printer");
const { Device } = Plugins;

function toDataURL(url: string, config: any) {
  return new Promise((resolve) => {




    fetch(
      url,
      { method: 'GET' }
    )
    .then( response => response.json() )
    .then( json => console.log(json) )
    .catch( error => console.error('error:', error) );


      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onload  = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          PrintBT.bitmapToHexadecimalString(
            { ...config, base64: reader.result },
            (s: any) => resolve(s),
            (e: any) => console.log(e)
          );
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
  });
}
const printStruk = async (data: any, copies: number = 1) => {
  // data = {
  //   id: 805,
  //   sales_order_number: "015-31",
  //   sales_order_date: "2021-02-25",
  //   est_delivery: "2021-02-25",
  //   so_status: "paid",
  //   sub_total: "94000",
  //   discount_amount: "0",
  //   grand_total: "94000",
  //   address: "",
  //   phone: "",
  //   additional_customer_name: "Mr Stark",
  //   customer_code: null,
  //   id_customer: 419,
  //   purchase_order_number: null,
  //   items: [
  //     {
  //       id: 1130,
  //       id_product: 337,
  //       product_name: " Es Kopi Susu Blusukan",
  //       qty: "2",
  //       sub_total: "27000",
  //       complement: [
  //         {
  //           id: 74,
  //           name: "Normal Ice",
  //           price: 5000,
  //           type: "Ice Level",
  //           is_default: 0,
  //         },
  //       ],
  //       status: "Open",
  //       price: "22000",
  //     },
  //     {
  //       id: 1129,
  //       id_product: 336,
  //       product_name: "Es Kopi Susu Pisang",
  //       qty: "2",
  //       subtotal: "20000",
  //       complement: [],
  //       status: "Open",
  //       price: "20000",
  //     },
  //   ],
  // };

  // ConfigStore.printLog(data.id)
  data.sales_order_date =dateFormat(new Date(), "yyyy-MM-dd HH:mm")


  const count = await SessionApi.postPrintLog(data.id)



  const info = await Device.getInfo();
  if (info.platform === "web") {
    await handleWeb(data, copies,count);
  } else {
    await handleMobile(data, copies,count);
  }
};
const handleMobile = async (data: any, copies: number,count:number=0) => {
  
  // alert(ConfigStore.outlet.catatan_struk)
  // return;
  
  const config = {
    type: "bluetooth",
    mmFeedPaper: ConfigStore.selectedPaper,
    id: ConfigStore.selectedStrukPrinters,  //ConfigStore.selectedPrinters.get("struk")
  };

  var img =""
  let logo: any


  if(ConfigStore.strukEnableLogo){
    if(!!ConfigStore.company.logo_url){
      if(ConfigStore.company.logo_url!=""){
        logo = await toDataURL(
          ConfigStore.server_url + ConfigStore.company.logo_url,
          config
        );  // kalo error, macet disini, gamasuk catch
        img =`[C]<img>${logo}</img>\n`
      }
    }else{
      
    }
  }

  let note="";
  if(!!ConfigStore.outlet.catatan_struk){
    let line=ConfigStore.outlet.catatan_struk.split("\n");;

    for(var x in line){
      note=note+`[C]<font size='small'>${line[x]}</font>\n`
    }
  }

  let printed = ``;
  if(count>0){
    printed=`\n\n[L]Cetakan ke: ${count}\n`
  }

  let changes=""
  if (!!data.cash&&data.cash>0) {
    changes=`[C]--------------------------------\n` +
    `[R]Tunai :[R]${moneyFormat(data.cash, "Rp.")}\n` +
    `[R]Kembali :[R]${moneyFormat(data.change, "Rp.")}\n`
  }

  let queue =""
  if(data.queue!=="null" && data.queue!=="" && !!data.queue){
    queue = `[C]<font size='tall'> #${data.queue}</font>\n`
  }



  let items=''
  if(ConfigStore.client.print_template=="TYPE 1"){
    items =data.items
    .map(
      (i: any) =>
        `[L]<b>${i.product_name}</b>\n[L]${i.qty}x ${moneyFormat(
          i.price,
          "@Rp."
        )}[R]<b>${moneyFormat(i.sub_total, "Rp.")}</b>\n${i.complement
          .map(
            (c: any) =>
              `[L]+ ${i.qty}x ${c.name} ${moneyFormat(
                Number(c.price),
                "@Rp."
              )}\n`
          )
          .join("")}`+
          // `[L]- Diskon: ${i.amount_discount}\n`+
          `${i.amount_discount>0?
            `[L]- Diskon: ${moneyFormat(i.amount_discount,"Rp.")}\n`
            :``
          }`
    )
    .join("[L]\n")
  } else if(ConfigStore.client.print_template=="TYPE 2"){
    items =data.items
    .map(
      (i: any) =>
        `[L]${i.qty}x <b>${i.product_name}</b>\n[L]  ${moneyFormat(i.price, "@Rp." )}[R]${moneyFormat(i.sub_total, "Rp.")}\n${i.complement.map(
            (c: any) =>
              `[L]  + ${i.qty}x ${c.name} ${moneyFormat(
                Number(c.price),
                "@Rp."
              )}\n`
          )
          .join("")}`+
          // `[L]- Diskon: ${i.amount_discount}\n`+
          `${i.amount_discount>0?
            `[L]  - Diskon: ${moneyFormat(i.amount_discount,"Rp.")}\n`
            :``
          }`
    )
    .join("[L]\n")
  }else{
    items =data.items
    .map(
      (i: any) =>
        `[L]<b>${i.product_name}</b>\n[L]${i.qty}x ${moneyFormat(
          i.price,
          "@Rp."
        )}[R]<b>${moneyFormat(i.sub_total, "Rp.")}</b>\n${i.complement
          .map(
            (c: any) =>
              `[L]+ ${i.qty}x ${c.name} ${moneyFormat(
                Number(c.price),
                "@Rp."
              )}\n`
          )
          .join("")}`+
          // `[L]- Diskon: ${i.amount_discount}\n`+
          `${i.amount_discount>0?
            `[L]- Diskon: ${moneyFormat(i.amount_discount,"Rp.")}\n`
            :``
          }`
    )
    .join("[L]\n")
  }


  const template =
    `${img}` +
    `[C]<b><font size='normal'>${ConfigStore.company.client_name}</font></b>\n` +
    `[C]<b><font size='normal'>${ConfigStore.company.outlet_name}</font></b>\n` +
    `[C]<font size='small'>${ConfigStore.company.alamat}</font>\n` +
    `[C]<font size='small'>${ConfigStore.company.telpon}</font>\n` +
    `[L]\n` +
    `[L]${dateFormat(data.sales_order_date, "EEEE, d MMMM yyyy - HH:mm")}\n` +
    `[L]${data.sales_order_number}\n` +
    `[L]${data.additional_customer_name}\n` +
    `[L]${data.payment_method}${" - "}Kasir: ${ConfigStore.fullname}\n` +
    `[C]================================\n` +
    items +
    `[C]--------------------------------\n` +
    `[R]Subtotal :[R]${moneyFormat(data.sub_total, "Rp.")}\n` +
    
    `[R]Diskon :[R]- ${moneyFormat(data.amount_discount, "Rp.")}\n` +
        `[R]Total :[R]${moneyFormat(data.grand_total, "Rp.")}\n` +

        `${changes}`+
        

        `[C]================================\n` +
        `[L]\n` +
        `[C]<font size='small'>${ConfigStore.company.invoice_add_text}</font>\n` +
        note +
        
        `[C]- Terima Kasih -\n` +
        `[L]\n` +
        queue +
        `[L]\n` +
        `[C]<qrcode size='15'>${data.sales_order_number}</qrcode>`+
        printed;

  try {
    for (let i = 0; i < copies; i++) {
      await PrintBT.printFormattedText(
        {
          ...config,
          text: template,
        },
        (e: any) => console.log("success: ", e),
        (e: any) => console.log("error: ", e)
      );
    }
  } catch (error) {
    alert(error);
    console.log(error);
  }
};

const handleWeb = async (data: any, copies: number,count:number=0) => {
  let qrcode = await QRCode.toDataURL(data.sales_order_number, {
    type: "image/jpeg",
    rendererOpts: {
      quality: 0.5,
    },
  });

  let prefixLogo =``;
  if(!!ConfigStore.company.logo_url && ConfigStore.company.logo_url!=="" && ConfigStore.company.logo_url!==null && ConfigStore.company.logo_url!=="null"){
    prefixLogo = `
    <img
      src="${ConfigStore.server_url + "/" + ConfigStore.company.logo_url}"
      alt=""
      class="logo"
    />
    `
  }
  let suffixText = ``

  if(!!ConfigStore.company.invoice_add_text && ConfigStore.company.invoice_add_text!=="" && ConfigStore.company.invoice_add_text!=="null"){
    suffixText = `
    <p class="text-center margin">${ConfigStore.company.invoice_add_text.replace("\n","<br>")}</p>
    `
  }

  if(!!ConfigStore.outlet.catatan_struk && ConfigStore.outlet.catatan_struk!=="" && ConfigStore.outlet.catatan_struk!=="null"){
    suffixText = suffixText + `
    <p class="text-center margin">${ConfigStore.outlet.catatan_struk.replace("\n","<br>")}</p>
    `
  }

  

  let changes="";
  if(!!data.cash && data.cash>0){
    changes=`<tr>
    <td colspan="3">
      <div class="border" />
    </td>
  </tr>
  <tr>
    <td colspan="2" class="text-right">
      Tunai :
    </td>
    <td class="text-right">${moneyFormat(data.cash, "Rp.")}</td>
  </tr>
  <tr>
    <td colspan="2" class="text-right">
      Kembali :
    </td>
    <td class="text-right">${moneyFormat(data.change, "Rp.")}</td>
  </tr>`
  }

  let queue = ``
  if(data.queue!="null" && data.queue!="" && !!data.queue){
    queue = `<p class="text-center margin; transform: scaleY(1.1);">#${data.queue}</p>`
  }

  let discItem = ``;

  let printed = ``;
  if(count>0){
    printed=`<p class="text-left margin">Cetakan ke: ${count}</p>`
  }

  let items =``;

  if(ConfigStore.client.print_template=="TYPE 1"){
    items=`${data.items
      .map(
        (i: any, k: number) =>
          `<tr>
            <td colspan="3">${i.product_name}</td>
          </tr>
          <tr>
            <td colspan="2">${i.qty}x @${i.price}</td>
            <td class="text-right">
              ${moneyFormat(i.sub_total, "Rp.")}
            </td>
          </tr>
        ${(i.complement as any)
          .map(
            (c: any) =>
              `<tr>
                <td colspan="2">
                  + ${i.qty}x ${c.name}
                </td>
                <td class="text-right">
                  ${moneyFormat(Number(c.price) * Number(i.qty), "Rp.")}
                </td>
              </tr>`
          )
          .join("")}`+
          `${i.amount_discount>0?
            `<tr>
              <td colspan="2">
                - Diskon: 
              </td>
              <td class="text-right">
                - ${moneyFormat(i.amount_discount, "Rp.")}
              </td>
            </tr>\n`
            :``
          }`
      )
      .join("")}`
  }else if(ConfigStore.client.print_template=="TYPE 2"){
    items=`${data.items
      .map(
        (i: any, k: number) =>
          `<tr>
            <td colspan="2">${i.qty}x ${i.product_name}</td>
          </tr>
          <tr>
          
            <td class="margin-left" colspan="2">&nbsp;&nbsp;@${i.price}</td>
            <td class="text-right align-top">
              ${moneyFormat(i.sub_total, "Rp.")}
            </td>
          </tr>
        ${(i.complement as any)
          .map(
            (c: any) =>
              `<tr>
                <td colspan="2">
                &nbsp;&nbsp;+ ${i.qty}x ${c.name}: ${moneyFormat(Number(c.price) * Number(i.qty), "Rp.")}
                </td>
                <td class="text-right">
                  
                </td>
              </tr>`
          )
          .join("")}`+
          `${i.amount_discount>0?
            `<tr>
              <td colspan="2">
              &nbsp;&nbsp;- Diskon: ${moneyFormat(i.amount_discount, "Rp.")}
              </td>
              <td class="text-right">
                
              </td>
            </tr>\n`
            :``
          }`
      )
      .join("")}`
  }else{
    items=`${data.items
      .map(
        (i: any, k: number) =>
          `<tr>
            <td colspan="3">${i.product_name}</td>
          </tr>
          <tr>
            <td colspan="2">${i.qty}x @${i.price}</td>
            <td class="text-right">
              ${moneyFormat(i.sub_total, "Rp.")}
            </td>
          </tr>
        ${(i.complement as any)
          .map(
            (c: any) =>
              `<tr>
                <td colspan="2">
                  + ${i.qty}x ${c.name}
                </td>
                <td class="text-right">
                  ${moneyFormat(Number(c.price) * Number(i.qty), "Rp.")}
                </td>
              </tr>`
          )
          .join("")}`+
          `${i.amount_discount>0?
            `<tr>
              <td colspan="2">
                - Diskon: 
              </td>
              <td class="text-right">
                - ${moneyFormat(i.amount_discount, "Rp.")}
              </td>
            </tr>\n`
            :``
          }`
      )
      .join("")}`
  }
  


  const content = `
  <div class="root">
  `+prefixLogo+`
  <p class="text-center margin">${ConfigStore.company.client_name}</p>
  <p class="text-center margin">${ConfigStore.company.outlet_name}</p>
  ${ConfigStore.company.alamat!=="null"? `<p class="text-center margin">${ConfigStore.company.alamat}</p>` :""}
  ${ConfigStore.company.telpon!=="null"? `<p class="text-center margin">${ConfigStore.company.telpon}</p>` :""}
  
  
  <table class="table">
    <thead>
      <tr>
        <td colSpan="3">
          <div class="border-double" />
        </td>
      </tr>
      <tr>
        <td colSpan="3">
          ${dateFormat(data.sales_order_date, "EEEE, d MMMM yyyy - HH:mm")}
        </td>
      </tr>
      <tr>
        <td colSpan="3">${data.sales_order_number}</td>
      </tr>
      <tr>
        <td colSpan="3">${data.additional_customer_name}</td>
      </tr>
      <tr>
        <td colSpan="1">${data.payment_method} ${" - "}   Kasir: ${ConfigStore.fullname}</td>
        
      </tr>
      <tr>
        <td colSpan="3">
          <div class="border-double" />
        </td>
      </tr>
    </thead>
    <tbody>
    ${items}
    </tbody>
    <tfoot>
      <tr>
        <td colspan="3">
          <div class="border" />
        </td>
      </tr>
      <tr>
        <td colspan="2" class="text-right">
          Subtotal :
        </td>
        <td class="text-right">${moneyFormat(data.sub_total, "Rp.")}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-right">
          Diskon :
        </td>
        <td class="text-right">- ${moneyFormat(data.amount_discount, "Rp.")}</td>
      </tr>
      <tr>
        <td colspan="2" class="text-right">
          Total :
        </td>
        <td class="text-right">${moneyFormat(data.grand_total, "Rp.")}</td>
      </tr>
      
      ${changes}
      <tr>
        <td colspan="3">
          <div class="border-double" />
        </td>
      </tr>
    </tfoot>
  </table>
  `+suffixText+`
  
  <p class="text-center margin">- Terima Kasih -</p>
  `+queue+`
  <img
    src="${qrcode}"
    alt=""
    class="qrcode"
  />
  ${printed}
</div>
  `;

  
  const print: any = window.open("", "", "height=600, width=800");
  if(!!print){
    print.document.open();
    print.document.write(`
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
      html, table, p {
        font-size: 12px;
      }
      .root {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      
      .text-center {
        text-align: center;
      }
      
      .table {
        width: 100%;
        margin: 15px 0;
      }
      
      .border-double {
        border-width: 1px 0px;
        border-style: dashed;
        border-color: black;
        height: 1px;
      }
      .margin {
        margin: 0;
      }
  
      .border {
        border-width: 1px 0px 0px;
        border-style: dashed;
        border-color: black;
      }
      
      .text-right {
        text-align: right;
      }

      .margin-left {
        margin-left: 150px;
        margin: 150px;
      }

      .align-top {
        vertical-align: top;
      }
      
      .logo {
        width: 80%;
        height: 4rem;
        align-self: center;
        object-fit: contain;
      }
  
      .qrcode {
        width: 8rem;
        height: 8rem;
        align-self: center;
      }
      
      .bg-red {
        background-color: red;
      }
      </style>
    </head>`);
    print.document.write(content);
    print.document.close();
    print.focus();
    setTimeout(() => {
      print.print();
    }, 300);
  }else{
    
  }
  
};

export default printStruk;
