import { observer } from "mobx-react";
import React, { HTMLAttributes } from "react";
import Page from "./Page";

export interface IModal extends HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  onDismiss: (e?: any) => void;
}

const Modal: React.FC<IModal> = (props) => {
  const { visible, onDismiss } = props;
  let cprops: any = { ...props };
  delete cprops.visible;
  delete cprops.onDismiss;
  if (!visible) return null;
  return (
    // <Page className="fixed z-50 inset-0 overflow-hidden">
    //   <div className="flex w-full items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 overflow-hidden">
    //     <div
    //       className="fixed inset-0 transition-opacity cursor-default"
    //       onClick={onDismiss}
    //     >
    //       <div className="absolute inset-0 bg-black opacity-75"></div>
    //     </div>
    //     <div
    //       className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full cursor-default"
    //       role="dialog"
    //       aria-modal="true"
    //       aria-labelledby="modal-headline"
    //       {...cprops}
    //     />
    //   </div>
    // </Page>
    // <Page className="fixed z-50 inset-0 overflow-y-auto">
    //   <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    //     <div
    //       className="fixed inset-0 transition-opacity"
    //       aria-hidden="true"
    //       onClick={onDismiss}
    //     >
    //       <div className="absolute inset-0 bg-black opacity-75"></div>
    //     </div>
    //     <span
    //       className="hidden sm:inline-block sm:align-middle sm:h-screen"
    //       aria-hidden="true"
    //     >
    //       &#8203;
    //     </span>

    //     <div
    //       className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
    //       role="dialog"
    //       aria-modal="true"
    //       aria-labelledby="modal-headline"
    //       {...cprops}
    //     ></div>
    //   </div>
    // </Page>
    <Page className="z-50">
      {{
        default: (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div
              className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-0"
              onClick={(e) => {
                //onDismiss(e);
                e.stopPropagation();
              }}
            />
            <div
              className="bg-white z-10 rounded-lg max-h-full sm:max-w-3xl sm:w-full overflow-x-auto"
              {...cprops}
            />
          </div>
        ),
      }}
    </Page>
  );
};

export default observer(Modal);
