import { Plugins } from "@capacitor/core";
import { navigate, RouteComponentProps } from "@reach/router";
import kelava from "@src/assets/images/pos-kelava.jpg";
import Page from "@src/libs/ui/Page";
import TextInput from "@src/libs/ui/TextInput";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore from "@src/model/config";
import UILoading from "@src/ui/Loading";
import Printer from "@src/ui/setup/Printer";
import TopBar from "@src/ui/setup/TopBar";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { Suspense } from "react";

const { Device } = Plugins;

const Setup: React.FC<RouteComponentProps> = observer((props) => {
  const form = ConfigStore.setup;
  const paths = ["server_url", "app_name", "app_token"];

  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform === "web") {
      navigate("order-list");
    } else {
      ConfigStore.loadSetup();
    }
  }, []);

  return (
    <Suspense fallback={<UILoading />}>
      <TopBar/>
      <Page {...props} className="h-full w-full grid grid-cols-12 bg-gray-100">
        {{
          default: (
            <>
              <div className="col-span-4 flex flex-col p-4 bg-white border-r border-gray-300">
                <div className="font-bold text-3xl">{ConfigStore.role} </div>
                <div className="flex flex-1 justify-center">
                  <div
                    className={`h-56 w-56 flex-none overflow-hidden bg-cover bg-center`}
                    style={{ backgroundImage: `url(${kelava})` }}
                  />
                </div>
              </div>
              <div className="col-span-4 flex flex-col p-4 border-r border-gray-300">
                <div className="font-bold text-xl">Server</div>
                {paths.map((path, key) => {
                  return (
                    <div key={key} className={"mb-2 flex flex-col"}>
                      <h2 className="text-xs mb-1 text-gray-700 capitalize">
                        {path.replace("_", " ")} :
                      </h2>
                      <TextInput
                        className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                        value={(form as any)[path]}
                        onChange={(e) => {
                          if (!!form) {
                            runInAction(() => {
                              (form as any)[path] = e.target.value;
                            });
                          }
                        }}
                      />
                    </div>
                  );
                })}
                <div className={"mb-2 flex flex-col mt-4"}>
                  <button
                    className="bg-blue-500 text-white p-2 font-bold text-lg flex-1 rounded hover:shadow-lg ripple"
                    onClick={() =>
                      ConfigStore.saveSetup(ConfigStore.setup._json)
                    }
                  >
                    Simpan
                  </button>
                </div>
              </div>
              {/* <Printer /> */}
            </>
          ),
        }}
      </Page>
    </Suspense>
  );
});

export default Setup;
