import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@capacitor-community/http";
import "./tailwind.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <App />
    <audio id="audio-element"
          
          autoPlay={false}
          >
            <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"
            // https://assets.coderrocketfuel.com/pomodoro-times-up.mp3
            ></source>
          </audio>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
