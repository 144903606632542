import { observer } from "mobx-react";
import React, { InputHTMLAttributes } from "react";

export default observer((props: InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <input
      className={
        "border-solid border-gray-500 border rounded text-gray-800 p-1 m-1 h-8 bg-white"
      }
      {...props}
      type="date"
    />
  );
});
