import { RouteComponentProps } from "@reach/router";
import Page from "@src/libs/ui/Page";
import ConfigStore from "@src/model/config";
import QueueStore from "@src/model/queue-repo";
import UILoading from "@src/ui/Loading";
import PageFlow from "@src/ui/queue/Flow";
import TopBar from "@src/ui/TopBar";
import React, { Suspense, useEffect } from "react";

const Queue: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    if(!ConfigStore.isWeb){
      ConfigStore.checkConfigStore()
    }
    
    let interval = setInterval(() => {
      QueueStore.load();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Suspense fallback={<UILoading />}>
      <Page {...props} className="h-full w-screen bg-gray-100">
        {{
          default: (
            <div className="h-full w-full overflow-hidden flex flex-col">
              <TopBar className="flex"  totem={ConfigStore.selectedTotem}/>
              <PageFlow />
            </div>
          ),
        }}
      </Page>
    </Suspense>
  );
};

export default Queue;
