import PromoAPI from "@src/api/PromoApi";
import { Model } from "@src/libs/model/model";
import { PromoSingle } from "./promo-single";



export class PromoRepository extends Model {
    list: PromoSingle[] = PromoSingle.hasMany(this);

    public async reload() {
        this.list=[]
        await PromoAPI.load().then((res:any) => {
            this._loadJSON({
                list:res.data
            })
        })
    }
}

const PromoStore = PromoRepository.create({
    localStorage: false,
    storageName: "PromoRepository",
  });
  export default PromoStore;