import Modal from "@src/libs/ui/Modal";
import QueueStore from "@src/model/queue-repo";
import { ArrowLeftOutline, ArrowRightOutline } from "heroicons-react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";

const ModalItem = observer(({ item, order, meta }: any) => {
  const dismiss = (e: any) => {
    runInAction(() => (meta.openModal = false));
    e.stopPropagation();
  };
  const nextStatus = QueueStore.getNextStatus(order.id);
  const prevStatus = QueueStore.getPrevStatus(order.id);

  return (
    <Modal visible={meta.openModal} onDismiss={dismiss}>
      <div className="bg-white px-4 pt-4">
        <div className="sm:flex sm:items-start">
          <div
            className={`h-40 w-40 flex-none overflow-hidden bg-cover bg-center rounded-lg`}
            style={{
              backgroundImage: `url("${item.image}")`,
            }}
          />
          <div className="ml-4">
            <div className="text-xl font-bold">
              {item.product_name} - {item.product_code}
            </div>
            <div className="font-bold">
              {item.sales_order_number} - {item.customer_additional_name}
            </div>
            <div className="text-gray-800">{item.getComplement}</div>
            <div className="text-gray-800">Quantity: {item.qty}</div>
            <div className="text-gray-800">{item.getComplement}</div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        {!!nextStatus && (
          <span className="flex w-full rounded-md shadow-sm sm:w-auto">
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full rounded-md border border-transparent px-8 py-4 bg-green-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={() => {
                order.nextStatus(item);
                runInAction(() => (meta.openModal = !meta.openModal));
              }}
            >
              {nextStatus?.status}
              <ArrowRightOutline size={25} className="ml-2" />
            </button>
          </span>
        )}
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto mx-3">
          <button
            type="button"
            className="inline-flex items-center justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={dismiss}
          >
            Batal
          </button>
        </span>
        {!!prevStatus && (
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="flex flex-row items-center justify-center w-full rounded-md border bg-orange-500 px-8 py-4 text-xl leading-6 font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={async () => {
                const res = await window.confirm("Apakah anda yakin?");
                if (res) {
                  order.prevStatus(item);
                  runInAction(() => (meta.openModal = !meta.openModal));
                }
              }}
            >
              <ArrowLeftOutline size={25} className="mr-2" />
              {prevStatus?.status}
            </button>
          </span>
        )}
        <div className="font-bold text-2xl flex flex-1 flex-col justify-center">
          <span className="font-normal text-base leading-3">Status</span>
          <span className="leading-8">{order.status}</span>
        </div>
      </div>
    </Modal>
  );
});

export default ModalItem;
