import OrderStore from "@src/model/order-repo";
import {
  ChevronDown,
  PencilAltOutline,
  PlusCircle,
  UserCircle,
  XCircle,
} from "heroicons-react";
import { observer } from "mobx-react";
import React from "react";

const CustomerInfo: React.FC = observer(() => {
  return (
    <div className="dropdown bg-white mb-2">
      {OrderStore.listDraft.length === 0 ? (
        <NewOrder />
      ) : (
        <>
          <div className="h-12 flex text-left items-center hover:bg-gray-300 px-2 button">
            <UserCircle
              color={"rgb(45, 55, 72)"}
              size={35}
              className="self-center"
            />
            <div className="mx-2 flex flex-col flex-1">
              <h1 className="text-gray-800 font-bold text-md leading-4">
                {OrderStore.currentOrder.name}
              </h1>
              <h4 className="text-xs font-normal">
                ({OrderStore.currentOrder.itemsQty} Items)
              </h4>
            </div>
            <button
              className="w-10 h-10 ml-3 rounded flex justify-center items-center ripple text-blue-400 hover:text-blue-600"
              onClick={(e) => {
                e.stopPropagation();
                OrderStore.updateTempOrder(OrderStore.idCurrentOrder);
              }}
            >
              <PencilAltOutline size={20} />
            </button>
            {OrderStore.listDraft.length > 1 && (
              <button
                className="w-10 h-10 ml-3 rounded flex justify-center items-center ripple text-red-300 hover:text-red-600"
                onClick={(e) => {
                  e.stopPropagation();
                  OrderStore.deleteDraftOrder(OrderStore.currentOrder.id);
                }}
              >
                <XCircle size={20} />
              </button>
            )}
            <ChevronDown size={20} />
          </div>
          <div className="dropdown-content bg-white w-full rounded-b-md border-gray-300 border-b border-l border-r overflow-auto max-h-80">
            <div className="h-full">
              {OrderStore.listDraft
                .filter((x) => x.id !== OrderStore.currentOrder.id)
                .map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="h-12 flex text-left items-center hover:bg-gray-200 px-2 dropdown-button w-full button"
                      onClick={(e) => {
                        OrderStore.idCurrentOrder = item.id;
                      }}
                    >
                      <UserCircle color={"rgb(45, 55, 72)"} size={35} />
                      <div className="mx-2 w-32 flex flex-col flex-1">
                        <h1 className="text-gray-800 font-bold text-md">
                          {item.name}
                        </h1>
                        <h4 className="text-xs font-normal">
                          ({item.itemsQty} Items)
                        </h4>
                      </div>
                      <button
                        className="w-10 h-10 rounded flex justify-center items-center ripple text-red-300 hover:text-red-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          OrderStore.deleteDraftOrder(item.id);
                        }}
                      >
                        <XCircle size={20} />
                      </button>
                    </div>
                  );
                })}
              <NewOrder />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const NewOrder = () => {
  return (
    <button
      className="h-12 flex text-left items-center bg-green-100 hover:bg-green-200 px-2 dropdown-button w-full"
      onClick={() => OrderStore.newTempOrder()}
    >
      <PlusCircle className="text-green-700" size={35} />
      <div className="mx-2 w-32">
        <h1 className="text-green-700 font-bold text-sm">Buat penjualan</h1>
      </div>
    </button>
  );
};

export default CustomerInfo;
