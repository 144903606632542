import DateInput from "@src/libs/ui/DateInput";
import TextInput from "@src/libs/ui/TextInput";
import QueueStore from "@src/model/queue-repo";
import { XCircle, Search, Calendar} from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React from "react";
// const { Device } = Plugins;

export default observer(() => {
  // useAsyncEffect(async () => {
  //   const info = await Device.getInfo();
  // }, []);

  const meta = useLocalObservable(() => ({
    date: false,
    search: false,
  }));

  return (
    <div className="flex items-center px-2">
      <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 h-8">
      {meta.date || QueueStore.filterDate!="" ? (
        <>
        <DateInput
          className="p-1 w-32 lg:w-56"
          value={QueueStore.filterDate}
          onChange={(e) =>
            runInAction(() => (QueueStore.filterDate = e.target.value))
          }
          onKeyDown={(e) => e.preventDefault()}
        />
        {/* <button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => (QueueStore.filterDate = ""))}
        >
          <XCircle size={20} className="text-gray-600" />
        </button> */}
        </>
      ):(
        <button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => {
            (QueueStore.filterDate = "")
            meta.date=true
          })}
        >
          <Calendar size={20} className="text-gray-600" />
        </button>
      )}
      </div>
      <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 h-8">
      {meta.search || QueueStore.filter!="" ? (
          <>
        <TextInput
          className="p-1 mr-1 w-32 lg:w-56"
          placeholder="Search"
          value={QueueStore.filter}
          onChange={(e) =>
            runInAction(() => (QueueStore.filter = e.target.value))
          }
        />
        <button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => (QueueStore.filter = ""))}
        >
          <XCircle size={20} className="text-gray-600" />
        </button>
        </>
        )

        :
        (<button
          className="p-2 rounded-md flex justify-center items-center ripple"
          onClick={() => runInAction(() => {
            (QueueStore.filter = "")
            meta.search=true
          })}
        >
          <Search size={20} className="text-gray-600" />
        </button>)
        
      }
      </div>
    </div>
  );
});
