import ProductStore from "@src/model/product-repo";
import { observer } from "mobx-react";
import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid } from "react-window";
import DataIsEmpty from "../DataIsEmpty";
import ProductItem from "./ProductItem";
const ReactPullToRefresh = require('react-pull-to-refresh');

const ProductList = observer(({ currentOrder }: any) => {
  let listProduct = ProductStore.listProduct;

  if (currentOrder.productMember.list.length > 0) {
    listProduct = currentOrder.productMember.listProduct;
  }

  return (
    
    <div className="flex flex-1 overflow-hidden">
      {/* <PullToRefresh
        pullDownContent={<PullDownContent />}
        releaseContent={<ReleaseContent />}
        refreshContent={<RefreshContent />}
        pullDownThreshold={200}
        onRefresh={() => ProductStore.reload()}
        triggerHeight={100}
        backgroundColor='white'
        startInvisible={true}> */}

      {Array.isArray(listProduct) && listProduct.length > 0 ? (
        <AutoSizer
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {({ height, width }: any) => {
            let baseW = width - 10,
              baseCol = 200,
              col = Math.floor(baseW / baseCol),
              modW = baseW % baseCol,
              colw = modW / col + baseCol,
              baseRow = 180,
              row = Math.ceil(listProduct.length / col);

            return (
              
      //     <ReactPullToRefresh 
      //   onRefresh={() => ProductStore.reload()}
      // >

        
              <Grid
                className="h-full"
                height={height}
                width={width}
                columnCount={col}
                columnWidth={colw}
                rowCount={row}
                rowHeight={baseRow}
                itemData={listProduct}
              >
                {(props: any) => {
                  const index = col * props.rowIndex + props.columnIndex;
                  return (
                    <div className={"GridItem p-2"} style={props.style}>
                      <ProductItem index={index} currentOrder={currentOrder} />
                    </div>
                  );
                }}
              </Grid>
              // </ReactPullToRefresh>
            );
          }}
        </AutoSizer>
      ) : (
        <DataIsEmpty description={"Tidak ada produk yang tersedia."} />
      )}
       {/* </PullToRefresh> */}
    </div>
    
  );
});

export default ProductList;
