import { OrderStatus, OrderStatusItem } from "@src/model/order-single";
import { ArrowRightOutline } from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import ModalItem from "./ModalItem";

export default observer((props: any) => {
  const order: OrderStatus = props.order;
  const item: OrderStatusItem = props.item;
  const lastStep = props.lastStep;
  const meta = useLocalObservable(() => ({
    openModal: false,
  }));

  return (
    <>
      <div
        className="flex flex-row border-b border-gray-200 mx-2 p-2 cursor-pointer hover:bg-gray-200 items-center"
        onClick={(e) => {
          runInAction(() => (meta.openModal = !meta.openModal));
        }}
      >
        <div className="truncate flex flex-col flex-1">
          <div className="font-bold text-gray-700">
            {item.sales_order_number} - {item.customer_additional_name}
          </div>
          <div className="text-xl font-bold text-gray-700">
            {item.product_name}
          </div>
          <div className="text-gray-700">
            {item.product_code} - Qty: {item.qty}
          </div>
          <div className="text-gray-700">{item.getComplement}</div>
        </div>
        {!lastStep && (
          <div>
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent p-2 bg-gray-200 text-lg leading-6 font-medium text-gray-500 shadow-sm hover:bg-green-500 hover:text-white focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
                onClick={(e) => {
                  order.nextStatus(item);
                  e.stopPropagation();
                }}
              >
                <ArrowRightOutline size={30} />
              </button>
            </span>
          </div>
        )}
      </div>
      <ModalItem meta={meta} item={item} order={order} />
    </>
  );
});
