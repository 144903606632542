export const getParams = () => {
  const params: any = {};
  let query = window.location.search.substr(1, window.location.search.length);
  let arrQuery = query.split("&");
  for (let q of arrQuery) {
    const p = q.split("=");
    params[p[0]] = p[1];
  }

  return params;
};
