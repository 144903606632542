import { Plugins } from "@capacitor/core";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";

// const imageToBase64 = require("image-to-base64");
const PrintBT = require("thermal-printer-cordova-plugin/www/thermal-printer");
const { Device } = Plugins;

// function toDataURL(url: string, config: any) {
//   console.log(ConfigStore.company);
//   return new Promise((resolve) => {
//     var xhr = new XMLHttpRequest();
//     xhr.onload = function () {
//       var reader = new FileReader();
//       reader.onloadend = function () {
//         PrintBT.bitmapToHexadecimalString(
//           { ...config, base64: reader.result },
//           (s: any) => resolve(s),
//           (e: any) => console.log(e)
//         );
//       };
//       reader.readAsDataURL(xhr.response);
//     };
//     xhr.open("GET", url);
//     xhr.responseType = "blob";
//     xhr.send();
//   });
// }
const printLabel = async (data: any, copies: number = 1) => {
  // data = {
  //   id: 805,
  //   sales_order_number: "015-31",
  //   sales_order_date: "2021-02-25",
  //   est_delivery: "2021-02-25",
  //   so_status: "paid",
  //   sub_total: "94000",
  //   discount_amount: "0",
  //   grand_total: "94000",
  //   address: "",
  //   phone: "",
  //   additional_customer_name: "Mr Stark",
  //   customer_code: null,
  //   id_customer: 419,
  //   purchase_order_number: null,
  //   items: [
  //     {
  //       id: 1130,
  //       id_product: 337,
  //       product_name: " Es Kopi Susu Blusukan",
  //       qty: "2",
  //       sub_total: "27000",
  //       complement: [
  //         {
  //           id: 74,
  //           name: "Normal Ice",
  //           price: 5000,
  //           type: "Ice Level",
  //           is_default: 0,
  //         },
  //       ],
  //       status: "Open",
  //       price: "22000",
  //     },
  //     {
  //       id: 1129,
  //       id_product: 336,
  //       product_name: "Es Kopi Susu Pisang",
  //       qty: "2",
  //       subtotal: "20000",
  //       complement: [],
  //       status: "Open",
  //       price: "20000",
  //     },
  //   ],
  // };

  

  const info = await Device.getInfo();
  if (info.platform === "web") {
    await handleWeb(data, copies);
  } else {
    await handleMobile(data, copies);
  }
};
const handleMobile = async (data: any, copies: number) => {

  
  const config = {
    type: "bluetooth",
    mmFeedPaper: ConfigStore.selectedPaper,
    id: ConfigStore.selectedLabelPrinters, //ConfigStore.selectedPrinters.get("label")
  };
  var template =``;

    data.map(
        (i: any) =>{

          let cmpl = "";
          let separator = "\n"
          let space = "\n"
          const maxChar=ConfigStore.labelMaxChar;

          for (var c = 0; c < ConfigStore.labelMarginLeft; c++) {
            separator=separator+" "
            space=space+" "
          }

          //ConfigStore.labelLineCount

          if((i.complement_str.length/maxChar)<=1){
            cmpl=i.complement_str+"\n\n\n\n\n"
          }else if((i.complement_str.length/maxChar)<=2){
            cmpl=i.complement_str+"\n\n\n\n"
            cmpl = [cmpl.slice(0, maxChar+1), separator, cmpl.slice(maxChar+1)].join('');
          }else{
            cmpl=i.complement_str.substring(0, 60)+"...\n\n\n\n"
            cmpl = [cmpl.slice(0, maxChar+1), separator, cmpl.slice(maxChar+1)].join('');
          }
          
          //(i.complement_str.length/31)<=1? i.complement_str+"\n\n\n\n\n":(i.complement_str.length/31<=2)? i.complement_str+"\n\n\n\n":i.complement_str.substring(0, 60)+"...\n\n\n\n"

          if (i.checklabel === 0) {
              return
          }else{
            template =template +
            `[L]${space}<b>${i.product_name}</b> [R]#${OrderStore.getDetailOrder.queue} `+
            `\n[L]${space}${cmpl}`
          }
        }
      )
      
      // template=template.slice(0, -6);
      // template=template+"[L]\n[L]";

  try {
    for (let i = 0; i < copies; i++) {
      await PrintBT.printFormattedText(
        {
          ...config,
          text: template,
        },
        (e: any) => console.log("success: ", e),
        (e: any) => console.log("error: ", e)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const handleWeb = async (data: any, copies: number) => {
  const content = `
  <div style="height: 0.2cm;">
  <table class="table">
    <tbody>
    <tr style="height: 0.2cm;", valign= "top">
                <td>
                  
                </td>
     </tr>
      ${data
        .map((i: any, k: number) => {
          var txt = ``;
          for (let p = 1; p <= i.qty; p++) {
            if (i.checklabel === 0) {
              continue;
            }
            txt =
              txt +
              `
                <tr style="height: 0.5cm;", valign= "top">
                <td>
                  <div class="border" />
                </td>
              </tr>

              <tr style="height: 0.5cm;",valign= "bottom">
                <td>
                ${i.product_name} - #${OrderStore.getDetailOrder.queue}
                </td>
              </tr>
              <tr  style="height: 1.1cm;", valign= "top">
              <td>
              ${i.complement_str}
              </td>
              </tr>
              
              `;
            //   <tr style="height: 0.2cm;",valign= "bottom">
            //   <td>
            //     <div class="border" />
            //   </td>
            // </tr>
          }
          return txt;
        })
        .join("")}
</tbody>

</table>
</div>
`;
  const print: any = window.open("", "", "height=600, width=800");
  print.document.open();
  print.document.write(`
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <style>
    html, table, p {
      font-size: 12px;
    }
    .root {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    
    .text-center {
      text-align: center;
    }
    
    .table {
      width: 5cm;
      margin-left: 10px;
      margin-right: 20px;
    }
    
    .border-double {
      border-width: 1px 0px;
      border-style: dashed;
      border-color: black;
      height: 1px;
    }
    .margin {
      margin: 0;
    }

    .border {
      border-width: 1px 0px 0px;
      border-style: dashed;
      border-color: black;
    }
    
    .text-right {
      text-align: right;
    }
    
    .logo {
      width: 80%;
      height: 4rem;
      align-self: center;
      object-fit: contain;
    }

    .qrcode {
      width: 8rem;
      height: 8rem;
      align-self: center;
    }
    
    .bg-red {
      background-color: red;
    }
    </style>
  </head>`);
  print.document.write(content);
  print.document.close();
  print.focus();
  setTimeout(() => {
    print.print();
  }, 300);
};

export default printLabel;
