import { Plugins } from "@capacitor/core";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore from "@src/model/config";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import { Refresh } from "heroicons-react";
import React from "react";
const { Device } = Plugins;
const PrintBT = require("thermal-printer-cordova-plugin/www/thermal-printer");

const Printers = observer((props:any) => {
    let prp = !!props.purpose?props.purpose:"";
  const meta = useLocalObservable(() => ({
    isMobile: false,
    listPrinter: [],
  }));
  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      runInAction(() => (meta.isMobile = true));
      await PrintBT.listPrinters(
        { type: "bluetooth" },
        (res: any) => runInAction(() => (meta.listPrinter = res)),
        (e: any) => console.log("list error: ", e)
      );
    }
  }, []);

  const reload = async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      runInAction(() => (meta.isMobile = true));
      await PrintBT.listPrinters(
        { type: "bluetooth" },
        (res: any) => runInAction(() => (meta.listPrinter = res)),
        (e: any) => console.log("list error: ", e)
      );
    }
  };

  if (!meta.isMobile) return null;

  return (
    <div className="col-span-4 flex flex-col p-4">
      {/* <div className="font-bold text-xl">Ukuran Kertas</div>
      <label className="inline-flex items-center mt-3">
        <input type="radio" className="form-radio h-5 w-5 text-red-600" />
        <span className="ml-2 text-gray-700">label</span>
      </label> */}
      <div className="flex flex-row">
        <div className="font-bold text-xl">Printer {prp}</div>
        
        <div  className="flex flex-grow">  </div>

        <button className="flex"
        onClick={reload}>
          <Refresh size={24} className="text-gray-600" />
        </button>
      </div>
      
      <span className="text-gray-600 text-sm font-normal">
        Pilih printer yang akan digunakan untuk {prp}. Pastikan bluetooth menyala dan sudah
        tersambung dengan printer.
      </span>
      <div className="my-2">
        {meta.listPrinter.map((item: any, key: number) => (
          <div
            key={key}
            className={`p-2 border-b border-gray-400 cursor-pointer button ${
              (prp=="struk"?ConfigStore.selectedStrukPrinters:ConfigStore.selectedLabelPrinters) === item.address //ConfigStore.selectedPrinters.get(prp)
                ? "bg-blue-500 text-white"
                : "bg-white"
            }`} 
            onClick={() =>
               runInAction(() => {
                 prp=="struk"?ConfigStore.selectedStrukPrinters=item.address : ConfigStore.selectedLabelPrinters=item.address
                 prp=="struk"?ConfigStore.selectedStrukName=item.name : ConfigStore.selectedLabelName=item.name
              
              })
            }
          >
            <div className="text-lg">{item.name}</div>
            <div className="text-sm">{item.address}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
export default Printers;
