import { OrderSingle } from "@src/model/order-single";
import OrderItem from "@src/ui/order/OrderItem";
import { observer } from "mobx-react";
import React from "react";
import DataIsEmpty from "../DataIsEmpty";
import CustomerInfo from "./CustomerInfo";

const CurrentOrderItems = observer(
  ({ currentOrder }: { currentOrder: OrderSingle }) => {
    return (
      <div className="flex flex-1 flex-col overflow-hidden">
        <CustomerInfo />
        <div className="flex flex-row px-2 items-center">
          <div className="flex font-bold text-2xl md:text-lg text-gray-800 flex-1">
            Current Order
          </div>
          {Array.isArray(currentOrder.items) && currentOrder.items.length > 0 && (
            <button
              className="font-bold bg-red-100 hover:bg-red-200 text-red-600 text-base md:text-sm px-4 p-2 rounded hover:shadow-lg ripple"
              onClick={() => currentOrder.deleteOrderItems()}
            >
              Clear All
            </button>
          )}
        </div>
        <div className="overflow-auto">
          <div className="flex flex-1">
            {Array.isArray(currentOrder.items) &&
            currentOrder.items.length > 0 ? (
              <div className="flex flex-1 flex-col">
                {currentOrder.items.map((item, index) => (
                  <OrderItem key={index} item={item} />
                ))}
              </div>
            ) : (
              <DataIsEmpty
                description={"Your collection list is empty."}
                imageProps={{
                  className: "h-32 w-32",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CurrentOrderItems;
