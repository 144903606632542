import Modal from "@src/libs/ui/Modal";
import TextInput from "@src/libs/ui/TextInput";
import OrderStore from "@src/model/order-repo";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";

const ModalCancel: React.FC = observer(() => {
  const currentOrder = OrderStore.updateOrder;
  if (!currentOrder) return null;

  if(OrderStore.updateOrder?.notes==="null"){
    OrderStore.updateOrder.notes="Pesanan dibatalkan";
  }


  const submit = async () => {
    OrderStore.cancel();
  };

  const dismiss = () => {
    runInAction(() => (OrderStore.isCancel = false));
  };

  return (
    <Modal visible={!!OrderStore.isCancel} onDismiss={dismiss}>
      <div className="bg-white px-4 pt-4 overflow-auto">
        <div className="sm:flex sm:items-start">
          <div className="text-center sm:text-left flex flex-1 flex-col">
            <h1
              className="text-xl font-bold leading-6 text-gray-800 mb-3"
              id="modal-headline"
            >
              Administrator
            </h1>
            <div>
              <div className={"mb-2 flex flex-col"}>
                <h2 className="text-xs mb-1 text-gray-700">Username :</h2>
                <TextInput
                  className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                  value={OrderStore.updateOrder?.username}
                  onChange={(e) => {
                    if (!!OrderStore.updateOrder) {
                      runInAction(() => {
                        OrderStore.updateOrder.username = e.target.value;
                      });
                    }
                  }}
                />
              </div>
              <div className={"mb-2 flex flex-col"}>
                <h2 className="text-xs mb-1 text-gray-700">Password :</h2>
                <TextInput
                  className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                  value={OrderStore.updateOrder?.password}
                  onChange={(e) => {
                    if (!!OrderStore.updateOrder) {
                      runInAction(() => {
                        OrderStore.updateOrder.password = e.target.value;
                      });
                    }
                  }}
                  type="password"
                />
              </div>

              <div className={"mb-2 flex flex-col"}>
                <h2 className="text-xs mb-1 text-gray-700">Catatan :</h2>
                <TextInput
                  className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                  value={OrderStore.updateOrder?.notes}
                  onChange={(e) => {
                    if (!!OrderStore.updateOrder) {
                      runInAction(() => {
                        OrderStore.updateOrder.notes = e.target.value;
                      });
                    }
                  }}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={submit}
            disabled={
              !OrderStore.updateOrder?.username &&
              !OrderStore.updateOrder?.password
            }
          >
            Submit
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-600 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple"
            onClick={dismiss}
          >
            Batal
          </button>
        </span>
      </div>
    </Modal>
  );
});

export default ModalCancel;
