import { Model } from "@src/libs/model/model";


export class PromoSingle extends Model {
    id: number = 0;
    name: string = "";
    info: string = "";
    is_active: string = "N";
    type: string = "";
    discount_percent: number =0;
    max_discount_value: number=0;
    discount_amount: number=0;
    created_time: string = "";
    id_client: number = 0;
  }
  