import { RouteComponentProps, useNavigate } from "@reach/router";
import { getParams } from "@src/libs/utils/getParams";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore from "@src/model/config";
import React from "react";
import UILoading from "@src/ui/Loading";
import {
  Plugins,
  FilesystemDirectory,
  FilesystemEncoding,
} from "@capacitor/core";
import { AppConfig } from "@src/config/app";
// import { initTotem } from "@src/services/Totem";
import { runInAction } from "mobx";
import AppVersionStore from "@src/model/appversion-repo";
import OrderStore from "@src/model/order-repo";
import { dateFormat } from "@src/libs/utils/stringFormat";

const { Filesystem } = Plugins;
const { Device } = Plugins;

const LoadingPage: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const check = async () => {
    let params = getParams();
    if (!!params && params.sess) {
      const res = await ConfigStore.checkSession(params.sess);
      if (!res) {
        //window.close();
      } else {
        runInAction(()=>{
          ConfigStore.socketIsConnected=0
        })
        OrderStore.list=[]
        // initTotem();

        navigate("/order-list", { replace: true });
      }
    } else {
      alert("Session not found. (SFA)");
      window.close();
    }
  };
  const checkMobileConf = async () => {
    try {
      await Filesystem.readFile({
        path: "setting.json",
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      })
        .then((conf) => {
          console.log(conf);
          ConfigStore.saveSetup(JSON.parse(conf.data));
        })
        .catch(async () => {
          const conf = {
            server_url: AppConfig.server,
            app_name: "pos_apps_sfa",
            app_token: "1360876e37e14876b7d9ba08e2746a9a",
            id_client: 0,
          };
          ConfigStore.saveSetup(conf);
        });

        AppVersionStore.initAppVersion()
        AppVersionStore.checkAppVersion()
        
        
        
        OrderStore.list=[]
      if (!!ConfigStore.user_token && ConfigStore.user_token!="") {

        if(ConfigStore.user_token==""){
          navigate("login");
        }else{
          if (ConfigStore.is_admin) {
            navigate("setup");
          } else {
            ConfigStore.socketIsConnected=0
            // initTotem();
  
            navigate("order-list");
            ConfigStore.loadPrintHeader();
          }
        }
        
      } else {
        navigate("login");
      }
    } catch (e) {
      console.log(e);
    }
  };
  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    
    OrderStore.filterDate=dateFormat(new Date(), "yyyy-MM-dd");

    if (info.platform === "web") {
      OrderStore.init()
      OrderStore.filterDate=dateFormat(new Date(), "yyyy-MM-dd");
      check();
    } else {
      checkMobileConf();
    }
  }, []);

  return <UILoading />;
};

export default LoadingPage;
