import { OrderStatus } from "@src/model/order-single";
import QueueStore from "@src/model/queue-repo";
import { observer } from "mobx-react";
import React from "react";
import DataIsEmpty from "../DataIsEmpty";
import Item from "./Item";

export default observer((props: any) => {
  const { index } = props;
  const status: OrderStatus = props.item;
  const l = QueueStore.getStatus.length;
  const n = ((100 / l) * (index + 1)) / 100;

  return (
    <div className="w-1/3 flex flex-col overflow-hidden h-full min-w-1/2 lg:min-w-1/4">
      <div
        className={`text-xl p-2 ${
          index + 1 > l / 2 ? "text-white" : "text-gray-700"
        }`}
        style={{
          backgroundColor: `rgba(43, 108, 176,${n})`,
        }}
      >
        {status.status}
      </div>
      <div className="h-full overflow-auto flex border-r border-gray-200">
        <div className="flex flex-1 flex-col">
          {status.getData.length > 0 ? (
            status.getData.map((item, key) => (
              <Item
                key={key}
                index={key}
                item={item}
                order={status}
                lastStep={l === index + 1}
              />
            ))
          ) : (
            <div className="col-span-8 md:col-span-9 h-full">
              <DataIsEmpty description={"Tidak ada data."} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
