import { Plugins } from "@capacitor/core";
import { useLocation, useNavigate } from "@reach/router";
import kelava from "@src/assets/images/kelava.png";
import kelavalogo from "@src/assets/images/kelavalogo.png";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore, { Totem } from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import FilterList from "@src/ui/orderlist/Filter";
import Filter from "@src/ui/queue/Filter";
import {
  ClipboardList,
  CogOutline,
  Collection,
  LogoutOutline,
  ShoppingCart,
  ChartPie,
  InformationCircle
} from "heroicons-react";

// import {androidClipboard} from 'react-icons-kit/ionicons/androidClipboard'
// import {androidCart} from 'react-icons-kit/ionicons/androidCart'
// import {androidList} from 'react-icons-kit/ionicons/androidList'
// import {documentText} from 'react-icons-kit/typicons/documentText'


import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React, { useEffect } from "react";
import TopBarTotem from "./totem/TopBarTotem";
import Icon from "react-icons-kit";
import { AppConfig } from "@src/config/app";
const { Device } = Plugins;

interface ITopBar {
  className?: string;
  totem: Totem
}

const TopBar: React.FC<ITopBar> = observer((props) => {
  const meta = useLocalObservable(() => ({
    isMobile: false,
    isConnected: true
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;
  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      runInAction(() => (meta.isMobile = true));
    }
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      OrderStore.load();
    }, 25000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (window.navigator.onLine) {
    meta.isConnected = true
  } else {
    meta.isConnected = false
  }
  //console.log('Initially ' + (window.navigator.onLine ? 'on' : 'off') + 'line');

  window.addEventListener('offline', function (e) {
    meta.isConnected = false
  });

  window.addEventListener('online', function (e) {
    meta.isConnected = true
  });

  return (
    <>
      <div className=" flex flex-col">
        {!meta.isConnected &&
          <text
            className={`border-b bg-red-700 justify-between flex flex-col text-white justify-items-center align-middle text-center text-xs ${props.className}`}
          >
            Anda sedang offline. Periksa koneksi internet anda.
          </text>
        }

        <div
          className={`border-b border-gray-400 justify-between flex flex-row bg-white ${props.className}`}
          style={{
            height: 60,
          }}
        >
          <div className="flex items-center px-2 overflow-hidden">
            {!meta.isMobile ? (
              <img
                className="h-6 w-auto rounded-lg overflow-hidden  mr-6"
                src={kelava}
                alt="Kelava"
              />
            ) : (
              <img
                className="h-6 w-auto rounded-lg overflow-hidden  mx-3"
                src={kelavalogo}
                alt="Kelavalogo"
              />
            )
            }
            <div className="flex flex-row items-center pr-2">
              <button
                className={`flex flex-col items-center px-2 h-12 text-left hover:bg-blue-100 truncate ${activePath === "/order-list"
                    ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                    : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                  }`}
                onClick={() => {
                  navigate("/order-list");
                  runInAction(() => {
                    OrderStore.openProductPicker = false;
                  });
                }}
              >
                <ClipboardList className="mr-1" size={24} />
                {/* <Icon
                icon={androidClipboard}
                size={24}
                style={{
                  backgroundSize: 30,
                  color: "#808080",
                }}
              /> */}


                <div className="flex flex-row">
                  <div>
                    Pesanan {" "}
                  </div>
                  <div className="text-red-600 font-bold text-base lg:text-base ml-1">
                    {OrderStore.unread > 0 ? "  (" +

                      OrderStore.unread
                      + ")" : ""}
                  </div>
                </div>



              </button>

              {ConfigStore.client.active_package != "5" ?
                <button
                  className={`flex flex-col items-center px-2 h-12 text-left ${activePath === "/order"
                      ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                      : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                    }`}
                  onClick={() => {
                    navigate("/order");
                    runInAction(() => {
                      OrderStore.openProductPicker = false;
                    });
                  }}
                >
                  <ShoppingCart className="mr-1" size={24} />
                  Penjualan
                </button>
                : <>

                </>
              }

              {ConfigStore.client.active_package != "5" ?
                <button
                  className={`flex flex-col items-center px-2 h-12 text-left truncate ${activePath === "/queue"
                      ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                      : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                    }`}
                  onClick={() => {
                    navigate("/queue");
                    runInAction(() => {
                      OrderStore.openProductPicker = false;
                    });
                  }}
                >
                  <Collection className="mr-1" size={24} />
                  {/* <Icon
                icon={androidList}
                size={24}
                style={{
                  backgroundSize: 30,
                  color: "#808080",
                }}
              /> */}

                  Proses
                </button>
                : <>

                </>
              }

              <button
                className={`flex flex-col items-center px-2 h-12 text-left truncate ${activePath === "/summary"
                    ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                    : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                  }`}
                onClick={() => {
                  if(!ConfigStore.isWeb){
                    window.open(AppConfig.server+"index.php?r=site/webView&t="+ConfigStore.user_token+"&u=manager/MainDashboard/DashboardOrderOutletDailyDetailU");
                  }else{
                    navigate("/summary");
                  }
                  

                  

                  // const url = 'https://dev.kelava.id/sfa/index.php?r=manager/MainDashboard/DashboardOrderOutletDailyDetailU';
                  // window.open(url, '_blank');
                }}
              >
                <ChartPie className="mr-1" size={24} />
                {/* <Icon
                icon={androidList}
                size={24}
                style={{
                  backgroundSize: 30,
                  color: "#808080",
                }}
              /> */}

                Summary
              </button>



              {(ConfigStore.isSetting) && meta.isMobile && (
                <button
                  className={`flex flex-row items-center px-4 h-12 text-left truncate ${activePath === "/setup"
                      ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                      : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                    }`}
                  onClick={() => {
                    navigate("/setup", { replace: true });
                    runInAction(() => {
                      OrderStore.openProductPicker = false;
                    });
                  }}
                >
                  <CogOutline size={24} className="text-gray-600" />
                </button>
              )}

              {/* {meta.isMobile && (
            <button
              className={`flex flex-row items-center px-4 h-12 text-left truncate ${
                activePath === "/setup"
                  ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                  : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
              }`}
              onClick={() => {
                let conf = window.confirm("Apakah anda yakin akan keluar?");
                if(conf){
                  navigate("/login", { replace: true });
                  ConfigStore.logout();
                  runInAction(() => {
                    OrderStore.openProductPicker = false;
                  });
                }
                
              }}
            >
              <LogoutOutline size={24} className="text-gray-600" />
            </button>
          )} */}


            </div>
          </div>
          <div className="flex flex-row">
            {/* {ConfigStore.client.active_package === "5" &&
              (location.pathname === "/order" || location.pathname === "/order-list") && <TopBarTotem />
            } */}
            {location.pathname === "/queue" && <Filter />}
            {location.pathname === "/order-list" && <FilterList qrCode={ConfigStore.client.active_package == "5"||ConfigStore.client.active_package == "4"} />}

            {/* {meta.isMobile && ( 
        <div className="flex flex-row border-solid border-gray-500 border rounded text-gray-800 m-1 ml-0 h-8">
          <button
            className="p-1 rounded-md flex justify-center items-center ripple"
            onClick={
              ()=>{
                ConfigStore.showModalBarcode=10
              }
            }
          >
            <InformationCircle size={24} className="text-gray-600" />
          </button>
        </div>
      )}  */}
          </div>
        </div>

      </div>

    </>
  );
});


export default TopBar;
