import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";

const checkAppVersion = async (limit: string = "") => {
  const res = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getAppVersion`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
    },
  });


  if (typeof res === "object") {
    return res;
  }
  return [];
};

const AppVersionAPI = {
    checkAppVersion,
  };
  
  export default AppVersionAPI;