import QueueStore from "@src/model/queue-repo";
import { observer } from "mobx-react";
import React, { Suspense } from "react";
import UILoading from "../Loading";
import List from "./List";

const Flow = observer(() => {
  return (
    <div className="flex h-full w-full overflow-hidden">
      <div className="w-full flex flex-row overflow-auto">
        {QueueStore.getList.map((item, key: number) => (
          <List key={key} index={key} item={item} />
        ))}
      </div>
    </div>
  );
});

const PageFlow = observer(() => {
  return (
    <Suspense fallback={<UILoading />}>
      <Flow />
    </Suspense>
  );
});

export default PageFlow;
