import {
  FilesystemDirectory,
  FilesystemEncoding,
  Plugins,
} from "@capacitor/core";
import { navigate } from "@reach/router";
import SessionApi from "@src/api/SessionApi";
import SessionMobileApi from "@src/api/SessionMobileApi";
import { AppConfig } from "@src/config/app";
import { Model } from "@src/libs/model/model";
import { dateFormat } from "@src/libs/utils/stringFormat";
// import { initTotem } from "@src/services/Totem";
import { runInAction } from "mobx";
import CustomerAPI from "../api/CustomerApi";
import OrderStore from "./order-repo";
import kelava from "@src/assets/images/pos-kelava.jpg";
import custom from "@src/assets/images/custom.png";
import ProductStore from "./product-repo";
import PaymentMethodStore from "./paymentmethod-repo";

const { Filesystem } = Plugins;

export class Company extends Model {
  client_name = "";
  outlet_name = "";
  alamat = "";
  telpon = "";
  kota = "";
  logo_url = "";
  invoice_add_text = "";
}

export class Outlet extends Model {
  id = null;
  nama = "";
  alamat = "";
  telpon = "";
  latitude = "";
  longitude = "";
  m_area = null;
  created_time = "";
  info = "";
  kota = "";
  provinsi = "";
  negara = "";
  img_url = "";
  code = null;
  is_live = 1;
  catatan_struk="";
  mon_start = null;
  mon_end = null;
  tue_start = null;
  tue_end = null;
  wed_start = null;
  wed_end = null;
  thu_start = null;
  thu_end = null;
  fri_start = null;
  fri_end = null;
  sat_start = null;
  sat_end = null;
  sun_start = null;
  sun_end = null;
  id_client = null;
  gallery = [];
  setting = [];
}

export class Client extends Model {
  id = null;
  client_name = "";
  created_date = "";
  email = "";
  pic_name = "";
  phone = "";
  npwp = "";
  active_package = "";
  package_exp = "";
  
  print_template="";
}

export class LoginForm extends Model {
  username = "";
  password = "";
  loading = false;
}

export class SetupForm extends Model {
  server_url: string = "";
  app_name: string = "";
  app_token: string = "";
  id_client: number = 0;
  id_outlet: number = 0;
}

export class Totem extends Model {
  id = 0;
  name = "";
  outlet = 0;
  room = "";
  socket_id = "";
  type = "";
}

export class ChannelPenjualan extends Model {
  id= 0;
  nama= "";
  img_url= "";
  img= "";
  status= "";
  id_client= 0;
  default_customer_name= null
}

export class ConfigRepository extends Model {
  id: string = "";
  server_url: string = "";
  sess_id: string = "";
  app_name: string = "";
  app_token: string = "";
  user_token: string = "";
  id_client: number = 0;
  id_outlet: number = 0;
  user_id: number = 0;
  username="";
  fullname="";

  channelPenjualan = ChannelPenjualan.hasMany(this);

  role = "";
  selectedPrinter = "";
  
  selectedStrukPrinters="";
  selectedLabelPrinters="";

  selectedStrukName="";
  selectedLabelName="";

  labelMarginLeft=2;
  labelMaxChar=28;
  labelLineCount=3;

  strukEnableLogo=false;
  

  selectedTotem = Totem.childOf(this);

  tempRoom =""; //indicator
  tempTotem =Totem.childOf(this) // Temporary selected totem

  showListTotem = false
  selectedPaper = 58;
  socket:any

  isLoggedIn = false;

  statusCamera = "hidden";

  form = LoginForm.childOf(this);
  setup = SetupForm.childOf(this);
  company = Company.childOf(this);
  outlet = Outlet.childOf(this);
  totems = Totem.hasMany(this);
  client = Client.childOf(this);
  socketIsConnected=0
  isWeb=false

  showModalBarcode = 0;
  isWaitingQR = false;

  isQueue = false;

  isSetting = false;

  loading = false;


  get orderMethod(){
    const pos = new ChannelPenjualan()
    pos.nama="POS"
    pos.img=kelava


    // const customs = new ChannelPenjualan()
    // customs.nama="CUSTOM"
    // customs.img=custom

    let channelss:ChannelPenjualan[]=[]

    

    channelss.push(pos)
    channelss.push(...this.channelPenjualan)
    //channelss.push(customs)

    //alert(JSON.stringify(channelss))
    return channelss
  }

  updateChecked(x: number) {
    if (this.totems[x].room+this.totems[x].name === this.tempRoom) {
      this.tempRoom = "";
      this.tempTotem= new Totem();
    } else {
      this.tempRoom =this.totems[x].room+this.totems[x].name;
      this.tempTotem= this.totems[x];
    }
  }

  get is_admin() {
    return this.role === "admin";
  }

  get is_manager() {
    return this.role === "manager";
  }

  get is_sales() {
    return this.role === "sales";
  }

  public async checkSession(token: string) {
    runInAction(()=>{
      this.server_url=AppConfig.server
      this.isWeb =true
    })
    
    const res = await SessionApi.checkToken(token);
    
    
    this._loadJSON(res);
    console.log(JSON.stringify(res))
    runInAction(()=>{
      this._loadJSON(res);
    })

    runInAction(()=>{
      ConfigStore.outlet = res.outlet
    })

    runInAction(()=>{
      ConfigStore.client = res.client
    })
    
    const queue = await SessionApi.getAddOnQueue();
    runInAction(()=>{
      this.isQueue = queue
      
    })

    if (Object.keys(res).length > 0) {
      this.form._loadJSON({
        username: res.username,
      });
      this.loadPrintHeader();
      return true;
    } else {
      let a = new ConfigRepository();
      this._loadJSON(a._json);
      return false;
    }

    
  }

  

  loadSetup() {
    this.setup._loadJSON(this._json);
  }

  async loadPrintHeader() {
    const res = await CustomerAPI.getCompany();
    this.company._loadJSON(res);
  }

  
  async printLog(id:number) {
    const res = await SessionApi.postPrintLog(id)

  }
  async login() {
    ProductStore.init()
    PaymentMethodStore.init()
    OrderStore.filterDate=dateFormat(new Date(), "yyyy-MM-dd");
    OrderStore.init()
    runInAction(()=>{
      this.form.loading = true;
      this.username = this.form.username;
    })
    // this.server_url=AppConfig.server

    runInAction(()=>{
      OrderStore.loading =false
      OrderStore.isSaving=false
    })
    
    try {
      const res = await SessionMobileApi.login(
        this.server_url,
        this.app_name,
        this.app_token,
        this.form._json
      ).catch(() => {
        this.form._loadJSON({
          loading: false,
        });
      });
      setTimeout(() => {
        if (this.form.loading) {
          this.form._loadJSON({
            loading: false,
          });
        }
      }, 10000);
      
    
    
      if (!!res.user_token) {
        
        this.fullname = res.user.fullname
        this.user_id = res.user.id

        this._loadJSON({
          channelPenjualan: res.channelPenjualan
        });

        if(!!res.outlet){
          runInAction(()=>{
            this.outlet = res.outlet
          })
        }
        
        if(!!res.client){
          runInAction(()=>{
            this.client = res.client
          })
        }
        
        
        let is_admin =
          res.user.roles.findIndex(
            (x: any) =>
              x.menu_path.indexOf("admin") > -1
              //  ||
              // x.menu_path.indexOf("corp_supervisor") > -1
          ) > -1;
        let is_sales =
          res.user.roles.findIndex(
            (x: any) => x.menu_path.indexOf("sales") > -1
            ||
            x.menu_path.indexOf("corp_supervisor") > -1
          ) > -1;

          let is_spv = res.user.roles.findIndex(
            (x: any) => 
            x.menu_path.indexOf("corp_supervisor") > -1
          ) > -1;

          

        this.form._loadJSON({
          loading: false,
        });
        ConfigStore.isSetting=is_spv;
  

        if (!is_admin && !is_sales) {
          alert("Akun tidak bisa digunakan di aplikasi ini.");
        } else {
          this._loadJSON({
            ...res.user,
            user_token: res.user_token,
            
            id_client: res.client.id,
            role: is_admin ? "admin" : "sales",
          });
          
          this.loadPrintHeader();
          this.saveSetup(this._json);
          if (is_admin) {
            navigate("/setup", { replace: true });
          } else {
            runInAction(()=>{
              ConfigStore.socketIsConnected=0
            })

            const queue = await SessionApi.getAddOnQueue();
            runInAction(()=>{
              this.isQueue = queue
            })
            
            // initTotem();
            navigate("/order-list");
            this.loadPrintHeader();
          }
        }
      }
    } catch (error) {
      this.form._loadJSON({
        loading: false,
      });
    }
    this.form.loading =false;
  }

  checkConfigStore(){
    if(this.company.client_name==="" || this.company.client_name===null || this.company.client_name===undefined){
      navigate("login", { replace: true });
    }
    if(ConfigStore.user_token==="" || ConfigStore.user_token===null || ConfigStore.user_token===undefined){
      navigate("login", { replace: true });
    }
  }

  async logout() {

    ProductStore.init()
    PaymentMethodStore.init()
    this._loadJSON({
      user_token: "",
      id_client: 0,
      is_admin: false,
      form: {
        username: "",
        password: "",
        loading: false,
      },
    });
    
    ConfigStore.user_token="";
    
    navigate("login", { replace: true });
    ConfigStore.user_token="";
    try {
      await Filesystem.deleteFile({
        path: "setting.json",
        directory: FilesystemDirectory.Data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async saveSetup(data: object) {
    try {
      await Filesystem.writeFile({
        path: "setting.json",
        data: JSON.stringify(data),
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
      this._loadJSON({
        ...data,
        form: {
          username: "",
          password: "",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}

const ConfigStore = ConfigRepository.create({
  localStorage: true,
  storageName: "ConfigRepository",
  encrypt: true,
});
export default ConfigStore;
