import { RouteComponentProps } from "@reach/router";
import { HTMLMotionProps, motion } from "framer-motion";
import { observer, useLocalObservable } from "mobx-react";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { Plugins, KeyboardInfo } from "@capacitor/core";
import { runInAction } from "mobx";

const { Keyboard, Device } = Plugins;

type OrientationType = "landscape" | "portrait";

interface IResponsivePage {
  mobile?: ReactElement;
  desktop?: ReactElement;
  default: ReactElement;
}

export interface IPage extends RouteComponentProps {
  children?: ReactNode | IResponsivePage;
}

const Page: React.FC<HTMLMotionProps<"div"> & IPage> = (props) => {
  const meta = useLocalObservable(() => ({
    current: "" as OrientationType,
    keyboardHeight: 0,
  }));

  const keyboard = async () => {
    const info = await Device.getInfo();
    const listenerKeyboard = (info?: KeyboardInfo) => {
      runInAction(() => {
        meta.keyboardHeight = info?.keyboardHeight || 0;
      });
    };

    if (info.platform !== "web") {
      Keyboard.addListener("keyboardWillShow", listenerKeyboard);
      Keyboard.addListener("keyboardWillHide", listenerKeyboard);
    }
  };

  const clearkeyboard = async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      Keyboard.removeAllListeners();
    }
  };

  useEffect(() => {
    const listener = () => {
      const { availWidth, availHeight } = window.screen;
      runInAction(() => {
        if (availWidth > availHeight) {
          meta.current = "landscape";
        } else {
          meta.current = "portrait";
        }
      });
    };
    window.addEventListener("orientationchange", listener);
    keyboard();
    return () => {
      window.removeEventListener("orientationchange", listener);
      clearkeyboard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!!props.children && !React.isValidElement(props.children)) {
    return (
      <motion.div
        className={props.className}
        style={{
          maxHeight: window.screen.height - meta.keyboardHeight,
        }}
      >
        {selectResolution(props.children as any, meta.current)}
      </motion.div>
    );
  }

  return <motion.div className={props.className}>{props.children}</motion.div>;
};

const selectResolution = (
  responsive: IResponsivePage,
  type: OrientationType
): ReactElement => {
  if (type === "landscape" && responsive.desktop) {
    return responsive.desktop;
  }

  if (type === "portrait" && responsive.mobile) {
    return responsive.mobile;
  }

  return responsive.default;
};

export default observer(Page);
