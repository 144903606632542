import { OrderItem } from "@src/model/order-single";
import { observer } from "mobx-react";
import React from "react";
import DataIsEmpty from "../DataIsEmpty";
import OrderItems from "./OrderItem";

const CurrentOrderItems = observer(
  ({ currentOrder }: { currentOrder: OrderItem }) => {
    return (
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="bg-white border border-gray-200 m-2 p-2 rounded">
          <div className="font-bold text-2xl md:text-lg text-gray-800">
            {currentOrder.additional_customer_name}
          </div>
          <div className="text-gray-800 text-sm">
            {currentOrder.sales_order_number}
          </div>
        </div>
        <div className="flex flex-col px-2 py-2">
          <div className="font-bold text-2xl md:text-lg text-gray-800">
            Current Order
          </div>
        </div>
        <div className="overflow-auto">
          <div className="flex flex-1">
            {Array.isArray(currentOrder.items) &&
            currentOrder.items.length > 0 ? (
              <div className="flex flex-1 flex-col">
                {currentOrder.items.map((item, index) => (
                  <OrderItems key={index} item={item} />
                ))}
              </div>
            ) : (
              <DataIsEmpty
                description={"Your collection list is empty."}
                imageProps={{
                  className: "h-32 w-32",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CurrentOrderItems;
