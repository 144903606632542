import Filter from "@src/ui/order/Filter";
import ModalItem from "@src/ui/order/ModalItem";
import ProductCategory from "@src/ui/order/ProductCategory";
import ProductList from "@src/ui/order/ProductList";
import { observer } from "mobx-react";
import React from "react";

const ProductPicker = observer(({ currentOrder }: any) => {
  return (
    <div className="md:col-span-8 xl:col-span-9 h-full overflow-hidden flex flex-col pt-3"
    style={{
      backgroundColor:"#f7fafc"
    }}
    >
      <Filter />
      <ProductCategory currentOrder={currentOrder} />
      <ProductList currentOrder={currentOrder} />
      <ModalItem currentOrder={currentOrder} />
    </div>
  );
});

export default ProductPicker;
