


import ConfigStore from "@src/model/config";

import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { runInAction } from "mobx";
import OrderStore from "@src/model/order-repo";

import {ic_cached} from 'react-icons-kit/md/ic_cached'
import {ic_camera_front} from 'react-icons-kit/md/ic_camera_front'
import {ic_camera_rear} from 'react-icons-kit/md/ic_camera_rear'
import {ic_cancel} from 'react-icons-kit/md/ic_cancel'
import Icon from "react-icons-kit";
import ModalCtrl from "@src/libs/ui/ModalCtrl";
import { dateFormat } from "@src/libs/utils/stringFormat";

const ModalScanCashier: React.FC = observer(() => {
  
  const meta = useLocalObservable(() => ({
    visibleSugest: false,
    focus: "",
  }));
  const cancel = () => {
    ConfigStore.showModalBarcode=0
  };

  const switchCam = async () => {
    if (ConfigStore.statusCamera === "front") {
      QRScanner.useBackCamera();
      // start scanning
      QRScanner.scan((_, text: string) => {
        if (!!text) {
          runInAction(() => {
            OrderStore.filter = text;
            OrderStore.filterDate = "";
            ConfigStore.showModalBarcode =0;

            OrderStore.scan()
            hidden();

            // alert(OrderStore.getList.length);
            // if(!!OrderStore.getList[0].id){
            //   OrderStore.idDetailOrder = OrderStore.getList[0].id
            // }
            // OrderStore.filterDate = dateFormat(new Date(OrderStore.getList[0].sales_order_date), "yyyy-MM-dd")
          });
          hidden();
        }
      });
      QRScanner.show();
      runInAction(() => {
        ConfigStore.statusCamera = "back";
      });
    }else{
        // camera permission was granted
        QRScanner.useFrontCamera();
        // start scanning
        QRScanner.scan((_, text: string) => {
          if (!!text) {
            runInAction(() => {
              OrderStore.filter = text;
              OrderStore.filterDate = "";
              ConfigStore.showModalBarcode =0;

              OrderStore.scan()
              hidden();

              // OrderStore.filter = text;
              // OrderStore.filterDate = "";
              // ConfigStore.showModalBarcode =0;
              // OrderStore.idDetailOrder = OrderStore.getList[0].id
            });
            hidden();
          }
        });
        QRScanner.show();
        runInAction(() => {
          ConfigStore.statusCamera = "front";
        });
    }
  }

  const hidden = () => {
    QRScanner.hide();
    QRScanner.cancelScan();
    QRScanner.pausePreview();
    ConfigStore.showModalBarcode=0;
    runInAction(() => {
      ConfigStore.statusCamera = "hidden";
    });
    ConfigStore.showModalBarcode=0;
  };

  const scan = async () => {
    if (!window || !window.QRScanner) {
      return null;
    }
    let QRScanner = window.QRScanner;
    const hidden = () => {
      QRScanner.hide();
      QRScanner.cancelScan();
      QRScanner.pausePreview();
      runInAction(() => {
        ConfigStore.statusCamera = "hidden";
      });
    };
    await QRScanner.prepare((_, status: QRScannerStatus) => {
      if (status.authorized) {
        if (ConfigStore.statusCamera === "hidden") {
          // camera permission was granted
          QRScanner.useFrontCamera();
          // start scanning
          QRScanner.scan((_, text: string) => {
            if (!!text) {
              runInAction(() => {
                OrderStore.filter = text;
                OrderStore.filterDate = "";
                ConfigStore.showModalBarcode =0;

                OrderStore.scan()
                hidden();

                // OrderStore.filter = text;
                // OrderStore.filterDate = "";
                // ConfigStore.showModalBarcode =0;
                // OrderStore.idDetailOrder = OrderStore.getList[0].id
              });
              hidden();
            }
          });
          QRScanner.show();
          runInAction(() => {
            ConfigStore.statusCamera = "front";
          });
        } else if (ConfigStore.statusCamera === "front") {
          // camera permission was granted
          QRScanner.useBackCamera();
          // start scanning
          QRScanner.scan((_, text: string) => {
            if (!!text) {
              runInAction(() => {
                OrderStore.filter = text;
                OrderStore.filterDate = "";
                ConfigStore.showModalBarcode =0;

                OrderStore.scan()
                hidden();
                // OrderStore.filter = text;
                // OrderStore.filterDate = "";
                // OrderStore.idDetailOrder = OrderStore.getList[0].id
                // OrderStore.idDetailOrder = OrderStore.getList[0].id
              });
              hidden();
            }
          });
          QRScanner.show();
          runInAction(() => {
            ConfigStore.statusCamera = "back";
          });
        } else {
          hidden();
        }
      } else if (status.denied) {
        // camera permission was permanently denied
        // you must use QRScanner.openSettings() method to guide the user to the settings page
        // then they can grant the permission from there
      } else {
        // permission was denied, but not permanently. You can ask for permission again at a later time.
      }
    });
  };

  

  
  return (
    <ModalCtrl visible={ConfigStore.showModalBarcode===3} onDismiss={cancel} 
    className="h-auto absolute bottom-0 bg-transparent">
      <div className="bg-transparent p-4 h-full rounded-md">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-col">
            
            <div className="flex flex-row">
              <div className="mb-2 flex flex-1 flex-row">
                <button
                  className={`opacity-75 hover:opacity-100 bg-white rounded-lg overflow-hidden relative align-middle`}
                  onClick={
                    switchCam
                  }
                >
                  <Icon
                    icon= {ConfigStore.statusCamera==="front"?ic_camera_rear:ic_camera_front}
                    size={48}
                    style={{
                      padding: 10,
                      backgroundSize: 30,
                      color: "#024f94ff",
                    }}
                  />
                  
                </button>
                <div className="w-12">

                </div>

                <button
                  className={`opacity-75 hover:opacity-100 bg-white rounded-lg overflow-hidden relative align-middle`}
                  onClick={
                    hidden
                  }
                >
                  <Icon
                    icon= {ic_cancel}
                    size={48}
                    style={{
                      padding: 10,
                      backgroundSize: 30,
                      color: "#024f94ff",
                    }}
                  />
                  
                </button>
                          
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
    </ModalCtrl>
  );
});



export default ModalScanCashier;
