import OrderStore from "@src/model/order-repo";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import CurrentOrderItems from "./CurrentOrderItems";

const CurrentOrder: React.FC = observer(() => {
  const currentOrder = OrderStore.updateOrder;

  const saveOrder = () => {
    OrderStore.update();
  };

  const cancel = () => {
    OrderStore.setUpdateOrder();
    runInAction(() => (OrderStore.openProductPicker = false));
  };

  return (
    <div className="md:col-span-4 xl:col-span-3 flex flex-col border-l border-gray-400 overflow-hidden h-full"
    style={{
      backgroundColor:"#f7fafc"
    }}
    >
      <CurrentOrderItems currentOrder={currentOrder} />
      <div className="flex flex-col p-2">
        <div className="flex flex-row gap-4">
          <button
            className="bg-blue-500 text-white p-2 font-bold text-lg flex-1 rounded hover:shadow-lg ripple"
            onClick={saveOrder}
          >
            Simpan
          </button>
          <button
            className="bg-white border border-gray-300 text-gray-700 p-2 font-bold text-lg flex-1 rounded hover:shadow-lg ripple"
            // disabled={currentOrder.items.length < 1}
            onClick={cancel}
          >
            Batal
          </button>
        </div>
      </div>
    </div>
  );
});

export default CurrentOrder;
