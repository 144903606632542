import { observer } from "mobx-react";
import React, { TextareaHTMLAttributes } from "react";

export default observer(
  (props: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
    return (
      <textarea
        className={
          "border-solid border-gray-500 border rounded text-gray-800 p-1 m-1 h-8"
        }
        {...props}
      />
    );
  }
);
