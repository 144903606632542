import { RouteComponentProps } from "@reach/router";
import Page from "@src/libs/ui/Page";
import UILoading from "@src/ui/Loading";
import { observer } from "mobx-react";
import React, { Suspense } from "react";
import kelava from "@src/assets/images/pos-kelava.jpg";
import TextInput from "@src/libs/ui/TextInput";
import ConfigStore from "@src/model/config";
import { runInAction } from "mobx";
import { AppConfig } from "@src/config/app";
import { navigate } from "@reach/router";

const Login: React.FC<RouteComponentProps> = observer((props) => {
  const form = ConfigStore.form;
  form.loading=false;
  

  document.addEventListener("backbutton", onBackKeyDown, false);  
  function onBackKeyDown(e:any) { 
    e.preventDefault(); 
     (navigator as NavigatorCordova).app.exitApp();
  }

  return (
    <Suspense fallback={<UILoading />}>
      <Page {...props} className="h-full w-full grid grid-cols-12 bg-gray-100">
        {{
          default: (
            <>
              <div className="col-span-6 flex flex-col justify-center items-center bg-white">
                <div
                  className={`h-56 w-56 flex-none overflow-hidden bg-cover bg-center`}
                  style={{ backgroundImage: `url(${kelava})` }}
                />
              </div>
              <div className="col-span-6 flex flex-col justify-center items-center">
                <div className="font-bold text-3xl">Point Of Sales</div>
                {AppConfig.server.includes("https://dev.")
                &&
                <div className="font-bold">Development</div>
                }
                <div className="p-4">
                  <div className={"mb-2 flex flex-col"}>
                    <h2 className="text-xs mb-1 text-gray-700">Username :</h2>
                    <TextInput
                      className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                      value={form?.username}
                      onChange={(e) => {
                        if (!!form) {
                          runInAction(() => {
                            form.username = e.target.value;
                            ConfigStore.username = e.target.value;
                          });
                        }
                      }}
                    />
                  </div>
                  <div className={"mb-2 flex flex-col"}>
                    <h2 className="text-xs mb-1 text-gray-700">Password :</h2>
                    <TextInput
                      className="mx-0 my-0 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
                      value={form?.password}
                      onChange={(e) => {
                        if (!!form) {
                          runInAction(() => {
                            form.password = e.target.value;
                          });
                        }
                      }}
                      type="password"
                    />
                  </div>
                  <div className={"mb-2 flex flex-col mt-8"}>
                    <button
                      className="bg-blue-500 text-white p-2 font-bold text-lg flex-1 rounded hover:shadow-lg ripple"
                      disabled={form.loading}
                      onClick={() => ConfigStore.login()}
                    >
                      Masuk
                    </button>
                  </div>
                </div>
              </div>
            </>
          ),
        }}
      </Page>
    </Suspense>
  );
});

interface NavigatorCordova extends Navigator {
  app: {
      exitApp: () => any; // Or whatever is the type of the exitApp function
  }
}


export default Login;
