import Modal from "@src/libs/ui/Modal";
import OrderStore from "@src/model/order-repo";
import { OrderProductSingle } from "@src/model/order-single";
import { runInAction, toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import printLabel from "./PrintLabel";


const ModalLabelItem = observer(() => {
  let shown = OrderStore.showLabel;

  useEffect(() => {
    if(OrderStore.showLabel==true){
      runInAction(() => (
        OrderStore.getDetailOrderLabel()
      ))
    }
    
  }, [OrderStore.showLabel]);

  //if (!shown)return null;
  const submit = () => {
    // console.log(toJS(OrderStore.labels))
    printLabel(OrderStore.labels);
  };

  const dismiss = () => {
    OrderStore.showLabel = false;
    shown = OrderStore.showLabel;
  };

  return (
    <Modal visible={OrderStore.showLabel} onDismiss={dismiss}>
      <div className="bg-white px-4 pt-4">
        {OrderStore.labels.map((item, idx) => {
          
          const active = item.checklabel == 1;
          return(
            <div key={idx} className={`bg-white hover:bg-gray-100 mb-1`}>
        <button
          key={idx}
          className={`${
            OrderStore.labels[idx].checklabel == 1 ? "bg-blue-600" : "bg-white"
          }`}
          style={{
            borderRadius: 10,
            borderWidth: 2,
            borderColor:
            OrderStore.labels[idx].checklabel == 1 ? "#1ACBDA" : "#fff",
            backgroundColor:
            OrderStore.labels[idx].checklabel == 1 ? "#1ACBDA33" : "#fff",
            flexGrow: 1,
            width: "100%",
          }}
        //   onClick={() => {
        //     onClickListener(idx)
        //   }}
          onClick={() => {
            runInAction(
              () => {
                if (OrderStore.labels[idx].checklabel == 1) {
                    OrderStore.labels[idx].checklabel = 0;
                    
                  } else {
                    OrderStore.labels[idx].checklabel = 1;
                    
                  }
                  OrderStore.showLabel=false;
                  OrderStore.showLabel=true;
                  
              }
            );
            
          }}
        >
            <div className="flex flex-row">
                <div className="text-center px-2 py-2 w-16">{idx + 1}. </div>
                <div className="text-left px-2 py-2">
                <div className="flex flex-col">
                    <div className="font-bold">{OrderStore.labels[idx].product_name}</div>
                    <div className="text-sm">{OrderStore.labels[idx].complement_str}</div>
                </div>
            </div>

            </div>
          
        </button>
      </div>
          )
        })}

        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              type="button"
              className="justify-center w-full rounded-md border border-transparent px-8 py-2 bg-green-600 text-xl leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={submit}
              // disabled={tempOrderProduct.qty ==== 0}
            >
              Print
            </button>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto">
            <button
              type="button"
              className="justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-xl leading-6 font-medium text-gray-700 shadow-sm hover:bg-gray-200 hover:text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:leading-5 ripple"
              onClick={dismiss}
            >
              Batal
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
});



export default ModalLabelItem;
