import { observer } from "mobx-react";
import React, { InputHTMLAttributes } from "react";

interface ICheckBox extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const CheckBox = observer((props: ICheckBox) => {
  const label = props.label;
  const cprops: any = { ...props };
  delete cprops.label;

  return (
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 text-blue-600"
        {...cprops}
      />
      <span className="ml-2 text-gray-700">{label}</span>
    </label>
  );
});

export default CheckBox;
