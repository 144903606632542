import { format as formatFNS, parseISO } from "date-fns";
import * as locales from "date-fns/locale";

export const dateFormat = (value: any, format?: string) => {
  const locale: string = "id";
  const inputFormat = format ? format : "dd MMM yyyy - HH:mm";
  if (typeof value === "string") {
    return formatFNS(parseISO(value), inputFormat, {
      locale: (locales as any)[locale],
    });
  }

  try {
    return formatFNS(value, inputFormat, {
      locale: (locales as any)[locale],
    });
  } catch (e) {
    return value;
  }
};

export const moneyFormat = (
  number: string | number,
  prefix: string = "",
  decimal = false
) => {
  let val = !number
    ? parseFloat("0")
    : typeof number == "string"
    ? parseFloat(number)
    : number;
  let res = val.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  if (!decimal) res = String(res).substr(0, res.length - 2);
  return `${prefix}${res}`;
};

export const numberFormat = (num: number, digits = 2) => {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "Ribu" },
    { value: 1e6, symbol: "Juta" },
    { value: 1e9, symbol: "M" },
    { value: 1e12, symbol: "T" },
    // { value: 1E15, symbol: "P" },
    // { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (
    (num / si[i].value).toFixed(digits).replace(rx, "$1") + " " + si[i].symbol
  );
};
