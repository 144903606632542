import { moneyFormat } from "@src/libs/utils/stringFormat";
import OrderStore from "@src/model/order-repo";
import { OrderProductSingle } from "@src/model/order-single";
import { Check, Trash } from "heroicons-react";
import { observer } from "mobx-react";
import React from "react";

interface IOrderItems {
  item: OrderProductSingle;
}

const OrderItems: React.FC<IOrderItems> = observer((props) => {
  const { item } = props;
  if (!item || item.isDeleted === "Y") return null;

  const edited = item.status === "Open" || !item.status;
  const deleteItem = async (e: any) => {
    e.stopPropagation();
    const res = await window.confirm(
      `Apakah yakin ingin menghapus ${item.product?.product_name}?`
    );
    if (!edited || !res) return;
    OrderStore.updateOrder.deleteOrderItem(item.id);
  };
  const update = () => {
    if (!edited) return;
    OrderStore.updateOrder.updateOrderProduct(item.id);
  };

  return (
    <div
      className={`mb-1 mx-2 py-2 border-b border-gray-300 flex flex-row xl:flex-row ${
        edited && "button"
      }`}
      style={{
        backgroundColor:"#f7fafc"
      }}
      onClick={update}
    >
      <div
        className={`h-12 w-12 flex-none overflow-hidden bg-cover bg-center rounded-lg`}
        style={{ backgroundImage: `url("${item.product?.image}")` }}
      />
      <div className="px-2 w-auto flex-1">
        <h1
          className={
            "text-md font-bold text-gray-800 overflow-hidden truncate max-h-3"
          }
        >
          {item.product?.product_name}
        </h1>
        <p className="text-gray-800 text-sm">{`${item.complement_str}`}</p>
        <p className="text-gray-800 text-xs">{`${
          item.complement_ttl !== 0
            ? moneyFormat(item.complement_ttl, "Rp. ")
            : ""
        }`}</p>
        <h4 className="text-gray-800 text-sm">
          x{item.qty} @ {moneyFormat(item.subtotal, "Rp. ")}
        </h4>
      </div>
      {!!edited ? (
        <button
          className="w-10 h-10 ml-3 bg-red-100 hover:bg-red-200 rounded-md flex justify-center items-center  hover:shadow-lg ripple"
          onClick={deleteItem}
          disabled={!edited}
        >
          <Trash size={20} className="text-red-600" />
        </button>
      ) : (
        <div className="w-10 h-10 ml-3 flex justify-center items-center">
          <Check size={20} className="text-green-600" />
        </div>
      )}
    </div>
  );
});

export default OrderItems;
