import SalesAPI from "@src/api/SalesOrder";
import { Model } from "@src/libs/model/model";
import { dateFormat } from "@src/libs/utils/stringFormat";
import ConfigStore from "./config";
import { OrderStatus } from "./order-single";

export class QueueRepository extends Model {
  list = OrderStatus.hasMany(this);
  filter = "";
  filterDate = dateFormat(new Date(), "yyyy-MM-dd");

  get getList(): OrderStatus[] {
    return this.list;
  }

  get getStatus() {
    let status = this.list.map((i) => ({
      id: i.id,
      status: i.status,
      sequence: i.sequence,
    }));

    return status;
  }

  // getListByFlow(id: number, fs = "", fd = ""): OrderStatusItem[] {
  //   // const fs = this.filter.toLowerCase();
  //   // const fd = dateFormat(new Date(this.filterDate), "yyyy-MM-ddd");
  //   let data: OrderStatusItem[] = [];
  //   let list = this.list.find((x) => x.id === id);
  //   if (!!list) {
  //     data = list.data.filter((item: any) => {
  //       let match = true;
  //       if (!!fs && !!item.product_name) {
  //         match = fuzzyMatch(fs, item.product_name.toLowerCase());
  //       }
  //       if (!!fs && !!item.customer_additional_name) {
  //         match =
  //           match ||
  //           fuzzyMatch(fs, item.customer_additional_name.toLowerCase());
  //       }
  //       if (!!fs && !!String(item.sales_order_number)) {
  //         match =
  //           match ||
  //           fuzzyMatch(fs, String(item.sales_order_number).toLowerCase());
  //       }
  //       if (!!fd && !!item.created_date) {
  //         match = !!match && fd === dateFormat(item.created_date, "yyyy-MM-dd");
  //       }
  //       return match;
  //     });
  //   }
  //   return data;
  // }

  getNextStatus(idStatus: number) {
    let idx = this.getStatus.findIndex((x) => x.id === idStatus);
    if (idx > -1 && idx + 1 < this.getStatus.length)
      return this.getStatus[idx + 1];
    return undefined;
  }

  getPrevStatus(idStatus: number) {
    let idx = this.getStatus.findIndex((x) => x.id === idStatus);
    if (idx > -1 && idx - 1 > -1) return this.getStatus[idx - 1];
    return undefined;
  }

  load() {
    SalesAPI.getListDeliveryItem().then((res) => {
      // if (this.list.length === 0 || res.length === 0) {
      const result = res.map((x) =>
        Object.assign(x, {
          data: x.data.map((item: any) =>
            Object.assign(item, {
              image: ConfigStore.server_url + "/" + item.image,
            })
          ),
        })
      );
      this._loadJSON({
        list: result,
      });
      // } else {
      //   this.list.forEach((item) => {
      //     let nItem = res.find((x) => item.id === x.id);
      //     item._loadJSON({
      //       data: nItem.data.map((item: any) =>
      //         Object.assign(item, {
      //           image: ConfigStore.server_url + "/" + item.image,
      //         })
      //       ),
      //     });
      //   });
      // }
    });
  }
}

const QueueStore = QueueRepository.create({
  localStorage: false,
  storageName: "QueueRepository",
});
export default QueueStore;
