import { observer } from "mobx-react";
import React, { InputHTMLAttributes } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

export default observer((props: CurrencyInputProps) => {
  return (
    <div className={
      "border-solid border-gray-500 border rounded text-gray-800 p-1  text-right w-full"
    }>
      {/* {props.type=="amount" && "Rp."} */}
      
      <CurrencyInput
            
            allowNegativeValue={true}
            groupSeparator=","

            allowDecimals={true}
            decimalsLimit={2}
            decimalSeparator="."

            


            prefix={props.type=="amount"?"Rp.":""}
            suffix={props.type=="percent"?"%":""}
            {...props}
            style={{
              width:110,
              textAlign:"end"
            }}
          />
      {/* {props.type=="percent" && " %"} */}
    </div>
    
  );
});
