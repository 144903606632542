import { Plugins } from "@capacitor/core";
import { useLocation, useNavigate } from "@reach/router";
import kelava from "@src/assets/images/kelava.png";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore from "@src/model/config";
import OrderStore from "@src/model/order-repo";
import { CogOutline, LogoutOutline,Printer,Newspaper, Collection,DesktopComputer } from "heroicons-react";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React from "react";
const { Device } = Plugins;

interface ITopBar {
  className?: string;
}

const TopBar: React.FC<ITopBar> = observer((props) => {
  const meta = useLocalObservable(() => ({
    isMobile: false,
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const activePath = location.pathname;
  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      runInAction(() => (meta.isMobile = true));
    }
  }, []);

  return (
    <div
      className={`border-b border-gray-400 justify-between flex flex-row bg-white ${props.className}`}
      style={{
        height: 48,
      }}
    >
      <div className="flex flex-1 items-center px-2 overflow-hidden">
        <img
          className="h-6 w-auto rounded-lg overflow-hidden"
          src={kelava}
          alt="Kelava"
        />
        <div className="flex flex-row flex-1 items-center px-2 ml-4">
          <div className="flex flex-1">
            {(ConfigStore.isSetting || ConfigStore.is_admin) && meta.isMobile && (
              <>
              <button
                className={`flex flex-row items-center px-4 h-12 text-left truncate ${
                  activePath === "/setup"
                    ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                    : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                }`}
                onClick={() => {
                  navigate("/setup");
                  runInAction(() => {
                    OrderStore.openProductPicker = false;
                  });
                }}
              >
                <CogOutline size={24} className="text-gray-600" />
                Server
              </button>

              <button
              className={`flex flex-row items-center px-4 h-12 text-left truncate ${
                activePath === "/print-struk"
                  ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                  : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
              }`}
              onClick={() => {
                navigate("/print-struk");
                runInAction(() => {
                  OrderStore.openProductPicker = false;
                });
              }}
              >
              <Newspaper size={24} className="text-gray-600" />
              Struk
              </button>

              <button
              className={`flex flex-row items-center px-4 h-12 text-left truncate ${
                activePath === "/print-label"
                  ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                  : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
              }`}
              onClick={() => {
                navigate("/print-label");
                runInAction(() => {
                  OrderStore.openProductPicker = false;
                });
              }}
              >
              <Collection size={24} className="text-gray-600" />
              Label
              </button>


              {ConfigStore.isSetting &&
                <button
                className={`flex flex-row items-center px-4 h-12 text-left truncate ${
                  activePath === "/order-list"
                    ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                    : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                }`}
                onClick={() => {
                  navigate("/order-list", { replace: true });
                  
                }}
                >
                <DesktopComputer size={24} className="text-gray-600" />
                POS
                </button>
              }
              

              </>
            )}
          </div>
          <div>
            {meta.isMobile && (
              <button
                className={`flex flex-row items-center px-4 h-12 text-left truncate ${
                  activePath === "/setup"
                    ? "bg-blue-100 text-blue-700 hover:text-blue-700 border-b-4 border-blue-500"
                    : "text-gray-700 hover:text-blue-700 hover:bg-blue-100"
                }`}
                onClick={() => {
                  let conf = window.confirm("Apakah anda yakin akan keluar?");
                  if(conf){
                      ConfigStore.logout();
                      runInAction(() => {
                        OrderStore.openProductPicker = false;
                      });  
                  }
                }}
              >
                <LogoutOutline size={24} className="text-gray-600" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default TopBar;
