import React, { HTMLAttributes } from "react";
import nodata from "@src/assets/images/loading_state.png";
import ClipLoader from "react-spinners/ClipLoader";

interface ITopBar {
  description: string;
  imageProps?: HTMLAttributes<HTMLImageElement>;
  onRefresh?: any;
}

const DataIsLoading = (props: ITopBar) => {
  let { imageProps, description, onRefresh } = props;
  let cprops: any = { ...props };
  delete cprops.description;
  delete cprops.imageProps;
  delete cprops.onRefresh;

  return (
    <div
      className="w-full h-full flex flex-col justify-center items-center"
      {...cprops}
    >
      <img
        className="h-auto w-32 bg-center"
        src={nodata}
        alt="Kelava"
        {...imageProps}
      />
      <div className="text-gray-500 text-sm flex flex-row m-2">
      
        <ClipLoader color={"#2b6cb0"} loading={true} size={24} />
        <h1 className="ml-2">{description}</h1>
          
      </div>
      {/* {typeof onRefresh === "function" && (
        <button
          className="ml-3 rounded flex justify-center items-center ripple text-blue-400 hover:text-blue-600"
          onClick={onRefresh}
        >
          <RefreshOutline size={20} />
          <span className="m-2">Refresh</span>
        </button>
      )} */}
    </div>
  );
};

export default DataIsLoading;
