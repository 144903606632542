import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";

const getList = async () => {
  const res = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiService/listProductVisit`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
    },
  });

  if (Array.isArray(res)) {
    return res;
  }
  return [];
};

const getCategory = async (member?: number) => {
  const res = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListCategory`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      outlet: ConfigStore.id_outlet,
      limit: "",
      customer: member,
    },
  });
  // console.log(JSON.stringify({
  //   url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getListCategory`,
  //   method: "post",
  //   data: {
  //     appName: ConfigStore.app_name,
  //     appToken: ConfigStore.app_token,
  //     client: ConfigStore.id_client,
  //     userToken: ConfigStore.user_token,
  //     outlet: ConfigStore.id_outlet,
  //     limit: "",
  //     customer: member,
  //   },
  // }))
  if (Array.isArray(res)) {
    return res;
  }
  return [];
};

const ProductAPI = {
  getList,
  getCategory,
};

export default ProductAPI;
