
import scannerTotem   from "@src/assets/images/scanner-totem.png";
import scannerCashier from "@src/assets/images/scanner-cashier.png";

import Modal from "@src/libs/ui/Modal";

import ConfigStore from "@src/model/config";

import { observer, useLocalObservable } from "mobx-react";
import React from "react";
import { runInAction } from "mobx";
import OrderStore from "@src/model/order-repo";

const ModalScan: React.FC = observer(() => {
  
  const meta = useLocalObservable(() => ({
    visibleSugest: false,
    focus: "",
  }));
  const cancel = () => {
    
    if(ConfigStore.showModalBarcode===1){
      ConfigStore.showModalBarcode=0
    }
    
  };
  
  const OrderMethod: {
    image: string;
    title:string;
    value: "TOTEM" | "CASHIER";
  }[] = [
    {
      image: scannerTotem,
      title: "(Totem)",
      value: "TOTEM",
    },
    {
      image: scannerCashier,
      title: "(Cashier)",
      value: "CASHIER",
    }
  ];





  const scan = async () => {
    if (!window || !window.QRScanner) {
      return null;
    }
    let QRScanner = window.QRScanner;
    const hidden = () => {
      
      
      QRScanner.hide();
      QRScanner.cancelScan();
      QRScanner.pausePreview();
      ConfigStore.showModalBarcode=0;
      runInAction(() => {
        ConfigStore.showModalBarcode=0;
        ConfigStore.statusCamera = "hidden";
      });
      ConfigStore.showModalBarcode=0;
    };
    await QRScanner.prepare((_, status: QRScannerStatus) => {
      if (status.authorized) {
        if (ConfigStore.statusCamera === "hidden") {
          ConfigStore.showModalBarcode=3
          // camera permission was granted
          QRScanner.useFrontCamera();
          // start scanning
          try{
          QRScanner.scan((_, text: string) => {
            try{
            if (!!text) {
              runInAction(() => {
                OrderStore.filter = text;
                OrderStore.filterDate = "";
                ConfigStore.showModalBarcode =0;
                OrderStore.idDetailOrder = OrderStore.getList[0].id
              });
              hidden();
            }
          }catch(error){
            alert(error);
          }
          });
        }catch(error){
          alert(error);
        }
          QRScanner.show();
          runInAction(() => {
            ConfigStore.statusCamera = "front";
          });
        } else if (ConfigStore.statusCamera === "front") {
          // camera permission was granted
          QRScanner.useBackCamera();
          // start scanning
          QRScanner.scan((_, text: string) => {
            try{
            if (!!text) {
              runInAction(() => {
                OrderStore.filter = text;
                OrderStore.filterDate = "";
                ConfigStore.showModalBarcode =0;
                OrderStore.idDetailOrder = OrderStore.getList[0].id
              });
              hidden();
            }
          }catch(error){
            alert(error);
          }
          });
          QRScanner.show();
          
          runInAction(() => {
            ConfigStore.statusCamera = "back";
          });
        } else {
          hidden();
        }
      } else if (status.denied) {
        // camera permission was permanently denied
        // you must use QRScanner.openSettings() method to guide the user to the settings page
        // then they can grant the permission from there
      } else {
        // permission was denied, but not permanently. You can ask for permission again at a later time.
      }
    });
  };

  return (
    <Modal visible={ConfigStore.showModalBarcode===1} onDismiss={cancel}
    className="h-64 absolute">
      <div className="bg-white p-4 h-full rounded-md">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left flex flex-1 flex-col">
            <h1
              className="text-xl font-bold leading-6 text-gray-800 mb-3"
              id="modal-headline"
            >
              Pilih Barcode Scanner
            </h1>
            <div className="mb-2">
              
              <div className="flex flex-row"
              
              >
                {OrderMethod.map((item, idx) => {
                  
                  return (
                    <div key={idx} className="mb-2 mr-5 flex flex-1 flex-col"
                      
                    >
                      <button
                        className={`opacity-75 hover:opacity-100 border-gray-400 border-2 rounded-lg overflow-hidden relative align-middle`}
                        onClick={() => {
                          
                          if(item.value==="TOTEM"){
                            if(ConfigStore.socketIsConnected===1 && ConfigStore.selectedTotem.room!==""){
                              ConfigStore.showModalBarcode=2
                            }else{
                              alert("Totem disconnected. Please connect to totem.")
                            }
                            
                          }else{
                            scan()
                            
                          }
                        }}
                      >
                        
                        <div
                         //className={`h-24 w-full flex-1 overflow-hidden bg-contain bg-center bg-no-repeat align-middle`}
                          style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: "9rem"}}
                        >
                            <div 
                            className={`h-24 w-full flex-none overflow-hidden bg-contain bg-center bg-no-repeat`}
                            style={{
                              backgroundImage: `url(${item.image})`,
                              }}>
                                
                            </div>
                            
                            
                            
                        </div>
                      </button>
                      <text className="text-base text-center">Barcode Scanner {"\n"}{item.title}</text>
                    </div>
                  );
                })}
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
    </Modal>
  );
});



export default ModalScan;
