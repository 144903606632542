import { Plugins } from "@capacitor/core";
import CheckBox from "@src/libs/ui/CheckBox";
import TextInput from "@src/libs/ui/TextInput";
import useAsyncEffect from "@src/libs/utils/useAsyncEffect";
import ConfigStore from "@src/model/config";
import printLabel from "@src/services/PrintLabel";
import printStruk from "@src/services/PrintStruk";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react";
import React from "react";
const { Device } = Plugins;
const PrintBT = require("thermal-printer-cordova-plugin/www/thermal-printer");

const PrinterTest = observer((props:any) => {
    let prp = !!props.purpose?props.purpose:"";
  const meta = useLocalObservable(() => ({
    isMobile: false,
    listPrinter: [],
  }));
  useAsyncEffect(async () => {
    const info = await Device.getInfo();
    if (info.platform !== "web") {
      runInAction(() => (meta.isMobile = true));
      await PrintBT.listPrinters(
        { type: "bluetooth" },
        (res: any) => runInAction(() => (meta.listPrinter = res)),
        (e: any) => console.log("list error: ", e)
      );
    }
  }, []);

  if (!meta.isMobile) return null;

  return (
    
    <div className="col-span-4 flex flex-col p-4">

    {prp=="label" && (<>
      <h2 className="text-xs mb-1 text-gray-700 capitalize">
        Margin kiri
      </h2>
      <TextInput
        className="mx-0  mb-2 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
        value={ConfigStore.labelMarginLeft}
        type="text" pattern="[0-9]*"
        onChange={(e) => {
          runInAction(() => {
            if(e.target.value!=""){
              ConfigStore.labelMarginLeft= parseInt(e.target.value);
            }else{
              ConfigStore.labelMarginLeft= 0;
            }
            
          })
        }}
      />


      {/* <h2 className="text-xs mb-1 text-gray-700 capitalize">
        Jumlah Baris (min. 2)
      </h2>
      <TextInput
        className="mx-0 mb-2 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
        value={ConfigStore.labelLineCount}
        type="text" pattern="[0-9]*"
        onChange={(e) => {
          runInAction(() => {
            if(e.target.value!="" && parseInt(e.target.value)>2){
              ConfigStore.labelLineCount= parseInt(e.target.value);
            }else{
              ConfigStore.labelLineCount=2;
            }
            
          })
        }}
      /> */}


      <h2 className="text-xs mb-1 text-gray-700 capitalize">
        Maks. Karakter per Baris
      </h2>
      <TextInput
        className="mx-0  mb-2 px-2 py-1 text-sm h-8 border-solid border rounded text-gray-800 border-gray-400 bg-gray-100"
        value={ConfigStore.labelMaxChar}
        type="text" pattern="[0-9]*"
        onChange={(e) => {
          runInAction(() => {
            if(e.target.value!=""){
              ConfigStore.labelMaxChar= parseInt(e.target.value);
            }else{
              ConfigStore.labelMaxChar=0;
            }
            
          })
        }}
      />
          
    </>)}


    {prp=="struk" && (
      <>
        <CheckBox
          label="Tampilkan logo di struk?"
          checked={ConfigStore.strukEnableLogo}
          onChange={(e) => {
            
              if (!ConfigStore.strukEnableLogo) {
                
                runInAction(() => {
                  ConfigStore.strukEnableLogo = !ConfigStore.strukEnableLogo;
                });
              } else {
                
                runInAction(() => {
                  ConfigStore.strukEnableLogo = !ConfigStore.strukEnableLogo;
                });
              }
            
          }}
        />

        {!!ConfigStore.strukEnableLogo && ConfigStore.strukEnableLogo && (
          <p className="text-red-500 text-xs">
            Peringatan: Print struk mungkin tidak aktif saat logo diaktifkan, hal itu dikarenakan ketidakcocokan logo dengan printer, nonaktifkan logo saat hal itu terjadi.
          </p>
        )}
      </>
    )}
      
      
      <div className={"mb-2 flex flex-col mt-4"}>
        <button
          className="bg-blue-500 text-white p-2 font-bold text-lg flex-1 rounded hover:shadow-lg ripple"
          onClick={() =>{
            if(prp=="label"){
              printLabel([
                {
                 product_name:"Lorem ipsum",
                 complement_str:""
                },
                {
                 product_name:"Dolor sit amet",
                 complement_str:"consectetur, adipiscing elit, sed do eiusmod"
                                 
                }
               ])
            }else if(prp=="struk"){
              printStruk({
                id: 123,
                sales_order_number: "012-34",
                sales_order_date: "2021-02-25",
                est_delivery: "2021-02-25",
                so_status: "paid",
                sub_total: "10000",
                discount_amount: "00",
                grand_total: "10000",
                address: "",
                phone: "",
                additional_customer_name: "Lorem Ipsum",
                customer_code: null,
                id_customer: 419,
                purchase_order_number: null,
                items: [
                  {
                    id: 1130,
                    id_product: 337,
                    product_name: "Lorem ipsum",
                    qty: "2",
                    sub_total: "5000",
                    complement: [
                      {
                        id: 74,
                        name: "dolor sit amet",
                        price: "1000",
                        type: "Ice Level",
                        is_default: 0,
                      },
                    ],
                    status: "Open",
                    price: "4000",
                  },
                  {
                    id: 1129,
                    id_product: 336,
                    product_name: "consectetur adipiscing",
                    qty: "2",
                    subtotal: "5000",
                    complement: [],
                    status: "Open",
                    price: "2500",
                  },
                ],
              })
            }
            

             
            }
          }
        >
          Test Print
        </button>
      </div>
      
    
    </div>
  );
});
export default PrinterTest;
