import SalesAPI from "@src/api/SalesOrder";
import fuzzyMatch from "@src/libs/utils/fuzzyMatch";
import { dateFormat } from "@src/libs/utils/stringFormat";
// import { sendToTotem } from "@src/services/Totem";
import { Model } from "../libs/model/model";
import OrderStore from "./order-repo";
import ProductStore from "./product-repo";
import {
  AddonProductSingle,
  CategorySingle,
  ProductSingle,
  SubCategorySingle,
} from "./product-single";
import { PromoSingle } from "./promo-single";

export class OrderStatus extends Model {
  id = 0;
  status = "";
  sequence = 0;
  data = OrderStatusItem.hasMany(this);

  get getData() {
    const fs = (this._parent as any)?.filter.toLowerCase();
    const fd = (this._parent as any)?.filterDate;
    return this.data.filter((item: any) => {
      let match = true;
      if (!!fs && !!item.product_name) {
        match = fuzzyMatch(fs, item.product_name.toLowerCase());
      }
      if (!!fs && !!item.customer_additional_name) {
        match =
          match || fuzzyMatch(fs, item.customer_additional_name.toLowerCase());
      }
      if (!!fs && !!String(item.sales_order_number)) {
        match =
          match ||
          fuzzyMatch(fs, String(item.sales_order_number).toLowerCase());
      }
      if (!!fd && !!item.created_date) {
        match = !!match && fd === dateFormat(item.created_date, "yyyy-MM-dd");
      }
      return match;
    });
  }

  async nextStatus(item: OrderStatusItem) {
    let s = (this._parent as any).getNextStatus(this.id);
    if (!!s) {
      await SalesAPI.updateStatus({
        id_delivery_item: item.id,
        status: s.id,
      });
      (this._parent as any).load();
    }
  }

  async prevStatus(item: OrderStatusItem) {
    let s = (this._parent as any).getPrevStatus(this.id);
    if (!!s) {
      await SalesAPI.updateStatus({
        id_delivery_item: item.id,
        status: s.id,
      });
      (this._parent as any).load();
    }
  }


  
}
export class OrderStatusItem extends Model {
  created_date = "";
  customer_additional_name = "";
  customer_name = "";
  est_delivery = "";
  id = 0;
  product_code = "";
  product_name = " ";
  qty = 0;
  sales_order_number = "";
  image = "";
  status = "";
  updated_by = null;
  updated_date = null;
  complement = AddonProductSingle.hasMany(this);

  get getComplement() {
    return this.complement.map((x) => x.name).join(", ");
  }
}
// export class OrderDelivery extends Model {
//   do_status = "";
//   id_delivery = null;
//   updated_by = null;
//   updated_date = null;
//   delivery_item = OrderDeliveryItem.hasMany(this);
// }

// export class OrderDeliveryItem extends Model {
//   id_product = 0;
//   price = 0;
//   product_name = "";
//   qty = 0;
//   status = "";
//   complement = [];
// }
export class OrderProductSingle extends Model {
  id = 0;
  id_product: number = 0;
  product_name: string = "";
  qty: number = 1;
  price: number = 0;
  sub_total: number = 0;
  status = "";
  remarks = "";
  isDeleted = "N";
  complement: AddonProductSingle[] = AddonProductSingle.hasMany(this);

  mode = "add";

  discount: string = "0";
  amount_discount: string = "0";

  discAmtItem: string ="0"
  discItem: string ="0"
  isDiscount =false;

  checklabel = 0;
  isLabelChecked = true;
  tempId = 0;

  

  get subtotal(): number {
    let subExtraData = this.complement_ttl;
    let onPrice = this.price;
    let quantity = this.qty;
    // if (extradata.length > 0) {
    //   extradata.map((x) => (subExtraData += Number(x.price)));
    // }
    let subtotal: number = Number(subExtraData) + Number(onPrice);

    return subtotal * quantity;
    // return subtotal;
  }

  get total(): number {
    // let subExtraData = this.complement_ttl;
    // let onPrice = this.price;
    // let quantity = this.qty;
    // // if (extradata.length > 0) {
    // //   extradata.map((x) => (subExtraData += Number(x.price)));
    // // }
    // let subtotal: number = Number(subExtraData) + Number(onPrice);
    // this.set_amount_discount()
    // this.set_discount()
    return (this.subtotal - parseFloat(this.amount_discount));
    // return subtotal;
  }

  get complement_str(): string {
    return this.complement
      .map((x) => x.name)
      .sort()
      .join(", ");
  }

  get complement_ttl(): number {
    let ttl: number = 0;
    this.complement.map((x) => (ttl += Number(x.price)));
    return ttl;
  }

  get product(): ProductSingle {
    let product: any = new ProductSingle();
    if ((this._parent as any)?.currentOrder?.productMember.list.length > 0) {
      product = OrderStore.currentOrder.productMember.list.find(
        (x) => x.id === this.id_product
      );
    } else {
      product = ProductStore.list.find((x) => x.id === this.id_product);
    }
    //this.price = product?.price
    return product;
  }

  addExtraData(complement: AddonProductSingle) {
    let ed = [...this.complement];
    let index = ed.findIndex(
      (x) => x.id === complement.id || x.type === complement.type
    );
    if (index > -1) {
      if (ed[index].id === complement.id) {
        ed.splice(index, 1);
      } else {
        ed[index] = complement._json;
      }
    } else {
      ed.push(complement);
    }
    this._loadJSON({
      complement: ed,
    });
  }


  // setDiscount(){
  //   if(this.discount>100){
  //     this.discount=100
  //   }else if (this.discount<0){
  //     this.discount=0
  //   }

  //   this.amount_discount=(this.subtotal*(this.discount/100))
  // }

  setDiscAmtItem(){
    if(parseFloat(this.discAmtItem)>this.price){
      this.discAmtItem=this.price.toString()
    }else if (parseFloat(this.discAmtItem)<0){
      this.discAmtItem="0"
    }

    this.discItem=parseFloat(((100*parseFloat(this.discAmtItem))/this.price).toFixed(2)).toString()
    this.set_amount_discount()
    this.set_discount()
  }
  setDiscItem(){
    if(parseFloat(this.discItem)>100){
      this.discItem="100"
    }else if (parseFloat(this.discItem)<0){
      this.discItem="0"
    }

    this.discAmtItem=(this.price*(parseFloat(this.discItem)/100)).toFixed(2).toString()
    this.set_amount_discount()
    this.set_discount()
  }

  //product
  // setDiscountAmount(){
  //   if(this.amount_discount>this.subtotal){
  //     this.amount_discount=this.subtotal
  //   }else if (this.amount_discount<0){
  //     this.amount_discount=0
  //   }

  //   // this.discount=Math.round( 
  //     this.discount=parseFloat(((100*this.amount_discount)/this.subtotal).toFixed(2));
  //     // )
  // }

  set_amount_discount(){
    this.amount_discount= (parseFloat(this.discAmtItem)*this.qty).toString()
  }

  set_discount(){
    this.discount=Math.round(parseFloat(((100*parseFloat(this.amount_discount))/this.subtotal).toFixed(2))).toString()
    
  }

}

export class ProductMember extends Model {
  categories = CategorySingle.hasMany(this);
  subcategories = SubCategorySingle.hasMany(this);
  list = ProductSingle.hasMany(this);
  activeCategory = 0;
  filter = "";

  get listProduct() {
    return this.list.filter((item) => {
      let match = true;
      let f = this.filter.toLowerCase();
      if (this.activeCategory !== 0) {
        match = item.id_category === this.activeCategory;
      }
      if (!!f) {
        let m = true;
        if (!!item.product_name) {
          m = fuzzyMatch(f, item.product_name.toLowerCase());
        }
        if (!!item.code) {
          m = m || fuzzyMatch(f, item.code.toLowerCase());
        }
        match = !!match && !!m;
      }
      return match;
    });
  }

  setCategory(id: number) {
    this._loadJSON({
      activeCategory: id,
    });
  }
}

export class OrderSingle extends Model {
  id = 0;
  unique_key="";
  method= "POS";//: "POS" | "GOFOOD" | "GRABFOOD" | "CUSTOM" 
  name?: string = "";
  phone?: string = "";
  sales_order_date: string = dateFormat(new Date(), "yyyy-MM-dd");
  sales_order_number?: string = "";
  ppn: number = 0;
  purchase_order_number?: string = "";
  customer_code?: string = "";
  id_customer?: number = 0;
  id_promo?: number = 0;
  items: OrderProductSingle[] = OrderProductSingle.hasMany(this);
  discount: string = "0";
  amount_discount: string = "0";
  payment_status: "NOW" | "LATER" = "NOW";
  payment_method: "CASH" | "DEBIT" | "OVO" | "GOPAY" | "DANA" | string = "";
  paid_method: "CASH" | "TRANSFER" | string = "";
  remarks: string = "";
  status: string = "";
  is_new_customer = false;
  is_member = false;
  is_cancel = "N";
  address = "";
  email = "";

  promo = PromoSingle.childOf(this)

  cash = 0;
  change = 0;
  isChange =false;
  isDiscount =false;
  
  
  showModalMethod = true
  productMember = ProductMember.childOf(this);

  get itemsQty(): number {
    let qty = 0;
    let items = this.items;
    items.forEach((x) => {
      qty += x.qty;
    });
    return qty;
  }

  get subtotal(): number {
    let subtotal = 0;
    let item = this.items;
    item.forEach((x) => {
      subtotal += Number(x.total); //changed subtotal to total
    });
    return subtotal;
  }

  get tax_amount(): number {
    let subttl = this.subtotal;
    let ppn = this.ppn;
    return subttl * (ppn / 100);
  }

  get total(): number {
    let subttl = this.subtotal;
    let tax = this.tax_amount;
    let disc = parseFloat(this.amount_discount);
    return subttl - disc + tax;
    //return this.subtotal;
  }

  get changes(): number {
    this.change=this.cash-this.total;
    return this.change;
  }

  get canSubmit() {
    let qtyNotEmpty = this.items.length > 0;
    if (this.payment_status === "NOW") {
      var x = true
      if(this.isChange){
        x = this.cash >= this.total
      }

      return !!qtyNotEmpty && !!this.payment_status && !!this.payment_method && x;
    } else if (this.payment_status === "LATER") {
      return !!qtyNotEmpty && !!this.payment_status;
    }
    return false;
  }

  get canComplete() {
    let qtyNotEmpty = this.items.length > 0;
    if (this.payment_status === "NOW") {
      var x = true
      if(this.isChange){
        x = this.cash >= this.total
      }

      return !!qtyNotEmpty && !!this.payment_status && !!this.payment_method && x && this.payment_method !==""  && this.payment_method !=="null" ;
    } else if (this.payment_status === "LATER") {
      return false
    }
    return false;
  }

  setChanges(){
    this.change=this.cash-this.total;
  }

  setDiscount(){
    if(parseFloat(this.discount)>100){
      this.discount="100"
    }else if (parseFloat(this.discount)<0){
      this.discount="0"
    }

    this.amount_discount=(this.subtotal*(parseFloat(this.discount)/100)).toString()
  }
  setDiscountAmount(){
    if(parseFloat(this.amount_discount)>this.subtotal){
      this.amount_discount=this.subtotal.toString()
    }else if (parseFloat(this.amount_discount)<0){
      this.amount_discount="0"
    }

    // this.discount=Math.round( 
      this.discount=parseFloat(((100*parseFloat(this.amount_discount))/this.subtotal).toFixed(2)).toString();
      // )
  }


  setPromo(){
    this.amount_discount="0"
    if(this.promo.type==="P"){
      // console.log("RUMUS: "+this.total+"*"+(this.promo.discount_percent/100))
      let disc = this.total * (this.promo.discount_percent/100)
      // console.log("disc ori: "+disc)
      if(disc>this.promo.max_discount_value && this.promo.max_discount_value!==0){
        this.amount_discount = this.promo.max_discount_value.toString()
      }else{
        this.amount_discount = disc.toString()
      }
      this.setDiscountAmount()
      
    }else{
      this.amount_discount =this.promo.discount_amount.toString()
      this.setDiscountAmount()
      
    }
  }

  deleteOrderItems() {
    this._loadJSON({
      items: [],
    });
    //sendToTotem(this._json);
  }

  addOrderProduct(item: OrderProductSingle) {
    let items = [...this.items];
    if (!!item) {
      let index = items.findIndex((x) => !!item && x.id === item.id);
      if (index > -1) {
        items[index] = item;
      } else {
        items.push(item);
      }
      this._loadJSON({
        items,
      });
      this.mergeOrderItem(item.id);
    }
    OrderStore.initOrderProduct();
    this.setPromo()
    //sendToTotem(this._json);
  }

  updateOrderProduct(id: number) {
    let item = this.items.find((x) => x.id === id);
    if (!!item) {
      OrderStore.tempOrderProduct._loadJSON({
        ...item._json,
        mode: "update",
      });
    }
    this.setPromo()
    //sendToTotem(this._json);
  }

  deleteOrderItem(id: number) {
    let items = [...this.items];
    let index = items.findIndex((x) => x.id === id);
    if (index > -1) {
      items.splice(index, 1);
    }
    this._loadJSON({
      items,
    });
    this.setPromo()
    OrderStore.tempOrderProduct._loadJSON(new OrderProductSingle()._json);
    //sendToTotem(this._json);
  }

  mergeOrderItem(id: number) {
    let items = [...this.items];
    let index = items.findIndex((x) => x.id === id);
    let item = items[index];
    if (!!item) {
      let it = items.filter(
        (x) =>
          x.id_product === item.id_product &&
          x.complement_str === item.complement_str
      );
      if (it.length > 1) {
        it[0].qty += item.qty;
        items.splice(index, 1);
      }
    }
    this._loadJSON({
      items,
    });
  }
}

export class OrderItem extends OrderSingle {
  // est_delivery = "";
  // grand_total = "";
  // id = 0;
  // sales_order_date = "";
  // sales_order_number = "";
  // so_status = "";
  // sub_total = 0;
  // additional_customer_name = "";
  // amount_discount = 0;
  // address = "";
  // customer_name = "";
  // phone = "";
  // customer_code = null;
  // discount_amount = "0";
  // id_customer = 0;
  // purchase_order_number = null;
  // items = OrderProductSingle.hasMany(this);
  src = "";

  est_delivery = "";
  grand_total = 0;
  so_status = "";
  sub_total = 0;
  additional_customer_name = "";
  amount_discount = "0";
  address = "";
  customer_name = "";
  deliver_date = "";
  queue="";
  q_id=0;
  q_status=0;

  payment_method="";

  username = "";
  password = "";
  notes="";
  get getItems() {
    return this.items;
  }

  get canCancel() {
    return (
      this.items.length === this.items.filter((x) => x.status === "Open").length
    );
  }

  get canSubmit() {
    return !!this.payment_method && this.items.length > 0 && this.payment_method!=="" && this.payment_method!=="null";
  }

  deleteOrderItem(id: number) {
    let items = [...this.items];
    let index = items.findIndex((x) => x.id === id);
    if (index > -1) {
      if (!items[index].status) {
        items.splice(index, 1);
      } else {
        items[index].isDeleted = "Y";
      }
    }
    this._loadJSON({
      items,
    });
    OrderStore.tempOrderProduct._loadJSON(new OrderProductSingle()._json);
  }

  mergeOrderItem(id: number) {
    let items = [...this.items];
    let index = items.findIndex((x) => x.id === id);
    let item = items[index];
    if (!!item) {
      let it = items.filter(
        (x) =>
          x.id_product === item.id_product &&
          x.complement_str === item.complement_str &&
          (!x.status || x.status === "Open")
      );
      if (it.length > 1) {
        it[0].qty += item.qty;
        items.splice(index, 1);
      }
    }
    this._loadJSON({
      items,
    });
  }

  // get total(): number {
  //   let subttl = this.subtotal;
  //   let tax = this.tax_amount;
  //   let disc = parseFloat(this.amount_discount);
  //   return subttl - disc + tax;

  //   //return this.grand_total;
  // }
  // get total(): number {
  //   let subttl = this.subtotal;
  //   let tax = this.tax_amount;
  //   let disc = parseFloat(this.amount_discount);
  //   return subttl - disc + tax;
  // }
}
