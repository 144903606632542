import { Router } from "@reach/router";
import React from "react";
import LoadingPage from "./pages/Loading";
import Login from "./pages/Login";
import Order from "./pages/Order";
import OrderList from "./pages/OrderList";
import PrintLabel from "./pages/PrintLabel";
import PrintSetup from "./pages/PrintSetup";
import PrintStruk from "./pages/PrintStruk";
import Queue from "./pages/Queue";
import Setup from "./pages/Setup";
import Summary from "./pages/Summary";



function App() {
  return (
    <Router className="h-full w-full">
      <LoadingPage default path="/" />
      <Login path="/login" />
      <Setup path="/setup" />
      <OrderList path="/order-list" />
      <Order path="/order" />
      <Queue path="/queue" />
      <PrintSetup path="/print-setup"/>
      <PrintStruk path="/print-struk"/>
      <PrintLabel path="/print-label"/>
      <Summary path="/summary"/>
    </Router>
  );
}

export default App;
