import api from "@src/libs/utils/api";
import ConfigStore from "@src/model/config";


const load = async () => {
  const res = await api({
    url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getPromoList`,
    method: "post",
    data: {
      appName: ConfigStore.app_name,
      appToken: ConfigStore.app_token,
      client: ConfigStore.id_client,
      userToken: ConfigStore.user_token,
      id_outlet: ConfigStore.id_outlet,
    },
  });


    // console.log(JSON.stringify({
    //   url: `${ConfigStore.server_url}/index.php?r=apiServicePOS/getPromoList`,
    //   method: "post",
    //   data: {
    //     appName: ConfigStore.app_name,
    //     appToken: ConfigStore.app_token,
    //     client: ConfigStore.id_client,
    //     userToken: ConfigStore.user_token,
    //     id_outlet: ConfigStore.id_outlet,
    //   },
    // }))
    //console.log(JSON.stringify(res))
  
  
    if (typeof res === "object") {
      return res;
    }
    return [];
  };
  
  const PromoAPI = {
      load,
    };
    
    export default PromoAPI;